import { callApi } from "./Utils";

export const getLemmas = async (accessToken) => {
    const user = await callApi('lemma/getLemmas', 'GET', accessToken);
    return user;
}

export const editLemma = async (accessToken, lemma) => {
    const user = await callApi('lemma/editLemma', 'PUT', accessToken, lemma);
    return user;
}