import React, { useEffect, useState, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Outlet, useNavigate } from "react-router-dom";
import { ThemeContext } from '../../App';
import { darkColor, lightColor } from '../../App';
import TopBar from '../TopBar/TopBar';

function MainPage() {
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth0();

    const { useDarkMode, setUseDarkMode } = useContext(ThemeContext);

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            navigate("/login");
        }
    }, [isAuthenticated, isLoading]);

    useEffect(() => {
        const darkColorTrans = '#171717dd';
        const lightColorTrans = '#d9d9d9dd';

        const booksElement = document.getElementById("booksDiv");
        if (booksElement) {
            if (useDarkMode) {
                booksElement.style.backgroundColor = darkColorTrans;
                booksElement.style.color = lightColor;
            } else {
                booksElement.style.backgroundColor = lightColorTrans;
                booksElement.style.color = darkColor;
            }
        }

        const crElement = document.getElementById("copyrightDiv");
        if (crElement) {
            if (useDarkMode) {
                crElement.style.color = lightColor;
            } else {
                crElement.style.color = darkColor;
            }
        }
    }, [useDarkMode]);

    return (
        <div className='mainPage'>
            {isAuthenticated &&
            <div>
                <Outlet/>
            </div>}
        </div>
    );
}
export default MainPage;