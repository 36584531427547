import React from 'react';
import './Switch.css';

export default function Switch(props) {

    const enabled = props.enabled;
    const setEnabled = props.setEnabled;
    const text = props.text;
    const textColor = props.textColor ?? 'white';

    const switchEnabledStyle = {
        backgroundColor: '#1677ff'
    };

    const switchIconStyle = {
        left: enabled ? 'calc(2em - 0.2em - 0.75em)' : 'calc(0.2em)'
    };

    return (
        <div 
            className={'switch'}
            onClick={() => setEnabled(!enabled)}>
            <div className={'switchText'}>
                <span style={{color: textColor}}>
                    {text}
                </span>
            </div>
            <div 
                className='switchSliderBar' 
                style={enabled ? switchEnabledStyle : {}}>
                <div className={'switchIcon'} style={switchIconStyle}>
                    {enabled &&
                    <div className='switchEnabled'/>}
                </div>
            </div>
        </div>
    );
}