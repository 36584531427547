import './ConfirmDelete.css';

import React from 'react';
import Button from '../../../Button/Button';

const ConfirmDelete = ({ onConfirm, onCancel, message }) => {
    return (
        <div className='confirmDelete'>
            <p>{message}</p>
            <div className='confirmDeleteButtons'>
                <Button 
                    text={'Delete'} 
                    textColor={'red'}
                    onClick={onConfirm}/>
                <Button 
                    text={'Cancel'} 
                    textColor={'white'}
                    onClick={onCancel}/>
            </div>
        </div>
    );
};

export default ConfirmDelete;
