import './SelectBox.css';

import React from 'react';

const SelectBox = ({ options, onChange, value }) => {
    const handleChange = (e) => {
        const value = e.target.value;
        onChange(value);
    }
    return (
        <select 
            className='selectBox' 
            onChange={handleChange}
            value={value}>
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default SelectBox;
