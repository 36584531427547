import './AdminPage.css';
import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from '../TopBar/TopBar';

function AdminPage(props) {
    return (
        <div>
            <TopBar user={props.user}/>
            <div className='mainPageContent adminPageContentBelowTopBar'>
                <Outlet/>
            </div>
        </div>
    );
}
export default AdminPage;