import './About.css';

import { Link } from 'react-router-dom';
import React from 'react';

export default function About() {
    return (
        <div className='aboutDiv'>
            <div className='aboutContent'>
                <AboutSection
                    header={'Overview'}
                    content={overview}/>
                <AboutSection
                    header={'What this App is Not'}
                    content={whatThisAppIsNot}/>
                <AboutSection
                    header={'What this App Is'}
                    content={whatThisAppIs}/>
                <AboutSection
                    header={'How this App Works'}
                    content={howThisAppWorks}/>
                <AboutSection
                    header={'Learning Rate'}
                    content={learningRate}/>
                <AboutSection
                    header={'Learning by New Testament Book'}
                    content={learningByNewTestamentBook}/>
                <AboutSection
                    header={'Learning by Grammar'}
                    content={learningByGrammar}/>
                <AboutSection
                    header={'Tips for Success'}
                    content={tipsForSuccess}/>
                <AboutSection
                    header={'Technical Information'}
                    content={technicalInformation}/>
                <AboutSection
                    header={"Release Notes"}
                    content={releaseNotes}/>
            </div>
        </div>
    );
}

function AboutSection(props) {
    const [expanded, setExpanded] = React.useState(false);

    const downChevron = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <path d="M6 9l6 6 6-6" />
        </svg>
    );     

    const upChevron = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <path d="M6 15l6-6 6 6" />
        </svg>
    );

    return (
        <div>
            <h1 
                onClick={() => {
                    setExpanded(!expanded);
                }}>
                {props.header}
                <div className='aboutContentExpandButton'>
                    {expanded ? upChevron : downChevron}
                </div>
            </h1>
            {expanded &&
            <div>
                {props.content}
            </div>}
        </div>
    );
}

const overview = [
    <p>
        Many students ask me, “What’s the best/easiest/fastest/funnest way to learn Greek vocabulary?” They ask this because once they take on the task of learning vocabulary, they find it to be a drudge. It’s difficult, slow—boring! I usually respond to students by saying, “The best way to learn vocabulary … is to learn vocabulary.” There is no easy way to do this. It takes effort. It takes time—a lot of time! It’s not glamorous or glorious, and it’s usually a pretty boring activity. But in order for the student to become more fluent in the Greek New Testament, they <em>must</em> learn their vocabulary!
    </p>,
    <p>
        To make things worse, the Greek New Testament contains more than 5,400 unique words and only about 300 of those words are used more than 50 times. If a student has mastered all the words used more than 10 times, they still only have a working vocabulary of about 1,000 words. As you can probably guess, the limited word lists found in the elementary Greek Grammars simply will not build a workable vocabulary—these books are not designed to build fluency, but basic competency in the language.
    </p>,
    <p>
        Unlike students of living languages, which may be spoken with other speakers, and which have a wealth of literature with which we can build our vocabularies, the student of the Greek New Testament has a limited body of literature, and much of the vocabulary is rare (nearly 4,000 words are used six times or fewer in the New Testament). So, any time you open the Greek New Testament, you will run across rare vocabulary that will cause you to pause and look up the word in a lexicon.
    </p>,
    <p>
        There are different methods and tools students can use to learn vocabulary. One could simply look up every unfamiliar word they run across and learn vocabulary inductively. But this would require reading the Greek New Testament in its entirety, and retention of those rare words would be severely lacking. Some students prefer to create notecards for each of the Greek words in the New Testament. But imagine going to the local Dollar Tree and purchasing 54 packs of 100 notecards, and then creating all those cards by hand! There are also printed flashcard sets available. However, few, if any, contain <em>all</em> the words of the Greek New Testament. There are also an increasing number of electronic “flashcard” systems available. There are far too many to mention here, but they are available. But many of these have an associated cost or are cluttered with advertisements and distractions which don’t enable learning.
    </p>,
    <p>
        This web app is designed to address many of these challenges to building your vocabulary of the Greek New Testament—and it’s designed to work at your own pace! While nothing can replace detailed lexical studies, familiarity with the vocabulary of the Greek New Testament will assist the student in gaining much greater <em>fluency</em> in reading the text of the Greek New Testament. After all, don’t we all wish we could simply pick up the Greek New Testament and sight read <em>any</em> passage competently?
    </p>
];
const whatThisAppIsNot = [
    <p>
        If you are looking for a vocabulary app with which you can challenge your friends, attain levels, earn trophies and badges, get encouragement from cartoon critters and have sirens and bells and whistles going off, this is not the app for you. After using numerous vocabulary building tools, I’ve found that many of those “engagement” tactics do nothing to actually enable learning. They do breed addiction—but just for the sake of addiction, to draw you into purchasing ad-free plans or add-ons. They lure students into completing more levels or earning more badges for the sake of the additional icons they may display on their profile or to entice users into purchasing learning plans that will “enhance their learning experience.” <em>Many of these vocabulary tools work very well</em>. The last thing I want to do is disparage any tool that helps students learn Greek. However, many of these tools are not free, so there is usually a monetary incentive behind these tools. Everyone needs to make a living, and these developers have chosen to make a living with these apps. That’s fine. But many of these tools also have built-in distractions which <em>hinder</em> learning, rather than <em>enable</em> it.
    </p>,
    <p>
        But you won’t see distractions in this web app. You also won’t see any advertising. This web app is provided freely as a service to students of the Greek New Testament. However, if you find this app useful and helpful in your ongoing study of the Greek New Testament, we ask you to please give to the <a href='https://globalservicenetwork.org/give/30815001' target="_blank">Daily Dose Ministries</a>, provided by The Southern Baptist Theological Seminary.
    </p>
];
const whatThisAppIs = [
    <p>
        We’ve tried to make this the easiest Greek New Testament vocabulary learning tool on the planet. All you have to do is log in, review Greek words and build your vocabulary! There are no spinning gizmos or flashing lights. There are no cute critters “encouraging” you along the way. There are no “standings” or “trophies” or “levels” or “achievements”—aside from the greater familiarity you will have with the words of the Greek New Testament and, hopefully, a greater ability to read, understand, assimilate, and teach the truths of the New Testament, thanks to your increased fluency in Greek!
    </p>,
    <p>
        Since this is a web-based application, there are no downloads, no apps, no clutter on your PC, Mac or mobile device. All you need is a web browser, an e-mail address, and a password—that’s it! Additionally, you never lose any of your progress—even if you interrupt one of your learning sessions.
    </p>
];
const howThisAppWorks = [
    <p>
        This app presents you with Greek words, 20 at a time. If there are any words that are new to you (or if you recently provided an incorrect response to a word), you will first see the word with its corresponding definition. You will then see those 20 Greek words, presented in random order with six random definitions. All you must do is click, tap or type the number for the correct definition and you will be given another Greek word. If you respond incorrectly, you will once again see the correct definition.
    </p>,
    <p>
        Certainly, your initial few times running through the words will seem overly simple and repetitive. This is because most Greek students using this app already have a working vocabulary. But, once you’ve mastered a set of words, the app will suddenly present you with five new Greek words that you haven’t seen before! Then, just continue reviewing words and building your vocabulary.
    </p>,
    <p>
        This web app is designed to help you learn <em>your</em> way and focus on your most recently learned words and your most troublesome words. So, those easy words that you learned years ago in Elementary Greek pop up far less frequently than that <em>hapax</em> you just learned yesterday. This keeps you in a state of constantly learning and improving your vocabulary. Additionally, if you decide to put the app down for a while and you forget some of those words—no problem! Any words that you miss will automatically pop up more frequently, reinforcing the learning you <em>need</em>.
    </p>,
    <p>
        This app is not designed to “end.” It just keeps going. As long as you are willing (and able) to learn new words, you will continue to see new words and build your vocabulary. There are no “levels” to complete or badges to earn. And progress is never lost, so if you are in the middle of vocabulary review, and the spouse asks you to help with the dishes, you no longer have the excuse of, “Just a minute, honey, I need to finish this level!” You close the app and pick up again at any time—exactly where you left off!
    </p>,
    <p>
        An additional feature of this web app is that you can choose to exclude proper names in your learning. For example, most folks think there is little advantage to learning that “Ἀαρών, ὁ” is the Greek lexical form for “Aaron.” However, you can include these words if you wish to become familiar with all the names of the New Testament.
    </p>
];
const learningRate = [
    <p>
        We recognize that all students learn at different rates and God has given students different gifts and abilities for learning languages and memorizing vocabulary. We also recognize that many students using this app for the first time already have a working Greek vocabulary (some may have a rather extensive vocabulary). Because of this, we’ve enabled this app to customize your learning experience so that you will gain the most benefit. This is called the Learning Rate, which you will see as a slider on the main page.
    </p>,
    <p>
        If you are a brand-new student, learning Greek for the first time, or if you are a student who really struggles to learn vocabulary, we recommend you set the Learning Rater further to the left. This will require you to see words (and respond correctly) more times before you see new words. In such cases, the added repetition reinforces your learning.
    </p>,
    <p>
        If you already have a broader Greek vocabulary, and you want to expand your vocabulary on the app quickly, we recommend you set the Learning Rate slider further to the right. This will require you to see each word only a few times before you are given new words. Over time, you may have to adjust the slider to suit your needs.
    </p>,
    <p>
        For new accounts, the Learning Rate defaults to 80%, or answering each word correctly at least six times before new words are introduced.
    </p>
];
const learningByNewTestamentBook = [
    <p>
        Once students learn the basics of the Greek language, they may want to spend focused time within one book of the New Testament. They want to be able to read an entire book fluently in order to understand and teach the message in a more meaningful and powerful way.
    </p>,
    <p>
        One of the weaknesses of many electronic flashcard systems is that they lack an <em>effective</em> means of learning words <em>by book</em> of the New Testament. Such systems exist, but they have one major flaw: they view the vocabulary of each New Testament book as a fixed set of words, rather than as a subset of a larger body of work (the New Testament). What this means is that, for example, if you want to learn the vocabulary in the book of Ephesians, after already having learned 1,000 words or more of the Greek New Testament, the first word presented to you in these systems will typically be “ὁ, ἡ, τό: the [article]”—because it’s the most frequent word in that book. But that’s also the first word you learned ten years ago in Elementary Greek! Then, you must <em>re-learn</em> all the words that you already know before you can begin learning the words unique to Ephesians.
    </p>,
    <p>
        This app overcomes that shortfall by viewing all the vocabulary of the Greek New Testament as <em>one body of literature</em>. If you have learned one word, it applies that learning to all the books in which it appears. Thus, all your vocabulary learning is <em>New Testament</em> learning.
    </p>
];
const learningByGrammar = [
    <p>
        Most frequently, students of the Greek New Testament learn the language from a Greek grammar textbook in college or seminary. Most professors require students to learn the vocabulary from whatever particular grammar they are using. This keeps all students “on the same page,” so to speak, and presents vocabulary that fits within certain learning objectives (e.g., students learn a group of first declension nouns, but no verbs within the same chapter).
    </p>,
    <p>
        Unfortunately, the popular Flashcards apps suffer the same challenges when presenting vocabulary from grammars as they do presenting vocabulary from a New Testament book: they see the vocabulary from, for example, Merkle and Plummer’s <em>Beginning with New Testament Greek</em> as a separate set of 345 Greek words, when in actuality, the vocabulary is a subset of all words in the Greek New Testament. Once you master the vocabulary from your grammar, and begin building your “Greek New Testament Vocabulary,” the first word presented to you in these systems will typically be “ὁ, ἡ, τό: the [article]”—<em>again</em>!
    </p>,
    <p>
        This web app overcomes that same challenge by integrating the vocabularies from many popular grammars (and the list keeps growing). Again, the vocabulary you learn from these grammars automatically becomes part of your New Testament vocabulary. When you finish learning the vocabulary from a Greek grammar, you then <em>build</em> your vocabulary based upon what you already have learned.
    </p>
];
const tipsForSuccess = [
    <p>
        As with all aspects of learning New Testament Greek, frequency and consistency in learning is critical. Spending 10-15 minutes, once or twice per day, reviewing vocabulary benefits the student far more than “cramming” vocabulary for four hours, late on a Saturday night. I encourage you to add vocabulary review to your regular habits. Because of the simplicity of this web app, you can review vocabulary while waiting in line at the grocery store! It doesn’t matter if you only get a chance to review 20 words—the key is getting the words before your eyes.
    </p>,
    <p>
        Although anyone can effectively use this app, it is more specifically designed around the needs of students enrolled in elementary and intermediate Greek courses. Because of this, we encourage first time students to stick to the Grammars keyed to this app. Learn the words prescribed by your professor and learn them well! Even if you master all the words of a particular grammar quickly, stick with it and keep reviewing! The vocabulary sections of your quizzes and exams should be “freebies,” in which you gain maximum points because you are so familiar with the words.
    </p>,
    <p>
        But wait, you might want to learn ALL the words of the Greek New Testament. Here is where I would encourage the new student to temper their expectations in their early classes. The first semester or two of Greek instruction is not intended to make you a Greek scholar fluent in the Greek New Testament. Since this is the case, don’t expect more from your vocabulary. Seek absolute mastery of the vocabulary you need for your classes. Then, as you gain greater skills and understanding of the Greek language, expand your vocabulary appropriately.
    </p>
];
const technicalInformation = [
    <p>
        The Greek text used for this web app comes from Kurt Aland et al., <em>Novum Testamentum Graece</em>, 28th Edition. (Stuttgart: Deutsche Bibelgesellschaft, 2012), one of the recognized scholarly, critical editions of the Greek New Testament. The English glosses have been compiled, and adjusted for brevity, from various lexica, grammars and dictionaries, but the final glosses are my own and I take responsibility for any inaccuracies. Please note, there may be minor differences between the glosses found in published grammar texts and with what you see here. Check with your textbook and ask your professor about those differences—as it may affect your grade!
    </p>,
    <p>
        Some words have been adjusted to reflect semantic root, rather than colloquial usage. Two notable examples are the absence of the Greek words “ἄρχων, -οντος, ὁ” and “δωρεάν” within the web app. The former is technically a participial form of ἄρχω “I begin, rule.” My intent is to highlight the verb and its manifold uses over against a limited, specific usage as “a ruler, governor.” Context within the Greek New Testament will guide the student in such cases. Likewise, the latter is an accusative form of the substantive δωρεά, which takes on an adverbial usage in some circumstances, typically translated as “freely” or “as a gift.” Again, since these are specific <em>uses</em> of the word and not separate <em>words</em>, I chose to consider all uses under one heading.
    </p>,
    <p>
        Please <Link to='../contact'>let us know</Link> of any errors in Greek words or English glosses, as well as any functional problems you may encounter with this app. It is our goal to provide a simple, complexity-free learning experience.
    </p>,
    <div className='aboutCreds'>
        <div className='aboutCredsSection'>
            <span>Greg Wolff</span>
            <span className='aboutCredsInfo'>Concept Design</span>
        </div>
        <div className='aboutCredsSection'>
            <span>Peter Glenn</span>
            <span className='aboutCredsInfo'>Software Development</span>
        </div>
    </div>
];

const releaseNotes = [
    <div className='releaseNotes'>
        <p>
            <strong>Version 2024.2.0</strong>
            <span className='dateStr'>(7/20/2024)</span>
            <ul>
                <li>Added the ability to filter by chapters of a given New Testament book.</li>
                <li>Added the ability to mute all app sounds.</li>
                <li>Added learning settings menu to save screen space on mobile devices. Added Daily Dose link to the learning page on mobile devices.</li>
                <li>Added simplified and traditional Chinese translations for lemma glosses.</li>
            </ul>
        </p>
        <p>
            <strong>Version 2024.1.6</strong>
            <span className='dateStr'>(2/20/2024)</span>
            <ul>
                <li>Boler’s Introduction to Classical & New Testament Greek was added. Note: only the New Testament words and glosses are included.</li>
                <li>A Preparatory Grammar for New Testament Greek (Dyer) was added. Note: Some vocabulary words were corrected from the text.</li>
                <li>Long’s Koine Greek Grammar was added. Note: several vocabulary errors in the text were corrected in the app.</li>
            </ul>
        </p>
        <p>
            <strong>Version 2024.1.5</strong>
            <span className='dateStr'>(2/14/2024)</span>
            <ul>
                <li>Wenham’s Elements of New Testament Greek grammar was added.</li>
                <li>Machen’s New Testament Greek For Beginners was added. PLEASE NOTE: Alternate verb forms are NOT included—just the lexical form.</li>
                <li>Lexical Aids for Students of New Testament Greek, 3rd Ed. (Metzger) was added.</li>
            </ul>
        </p>
        <p>
            <strong>Version 2024.1.4</strong>
            <span className='dateStr'>(2/10/2024)</span>
            <ul>
                <li>Merkle/Plummer's Greek Word Studies for Everyone has been added to the list of available grammars</li>
                <li>Grammar list now sorts alphabetically</li>
                <li>Fixed minor styling bugs</li>
            </ul>
        </p>
        <p>
            <strong>Version 2024.1.3</strong>
            <span className='dateStr'>(2/5/2024)</span>
            <ul>
                <li>Fixed bug where the 6th option was never the correct answer</li>
            </ul>
        </p>
        <p>
            <strong>Version 2024.1.2</strong>
            <span className='dateStr'>(2/3/2024)</span>
            <ul>
                <li>Duff’s Elements of New Testament Greek has been added to the list of available grammars</li>
            </ul>
        </p>
        <p>
            <strong>Version 2024.1.1</strong>
            <span className='dateStr'>(1/30/2024)</span>
            <ul>
                <li>Added a release notes section to the about page</li>
                <li>Added a link to the Daily Dose of Greek website for Desktop users</li>
                <li>Made Spacebar key advance definition cards</li>
            </ul>
        </p>
        <p>
            <strong>Version 2024.1.0</strong>
            <span className='dateStr'>(1/16/2024)</span>
            <ul>
                <li>Initial release</li>
            </ul>
        </p>
    </div>
];