import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { selectBook } from '../controllers/BookController';
import CheckBox from './CheckBox';

export default function BookSelector(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [bookTitleColor, setBookTitleColor] = useState('white');

    const selected = props.book.isSelected;
    const setSelected = props.setSelected;

    useEffect(() => {
        const learnedWordRatio = props.book.learnedLemmas/props.book.totalLemmas;
        const bookTitleColor = getProgressColor(learnedWordRatio);
        setBookTitleColor(bookTitleColor);
    }, [props.book.learnedLemmas, props.book.totalLemmas]);

    return (
        <div className='book'>
            <CheckBox 
                checked={selected}
                setChecked={async (newVal) => {
                    setSelected(newVal);
                    const accessToken = await getAccessTokenSilently();
                    await selectBook(accessToken, props.book.id, newVal);
                }}
                text={`${props.book.sbl} (${props.book.learnedLemmas}/${props.book.totalLemmas} words)`}
                textColor={bookTitleColor}/>
        </div>
    );
}

export function getProgressColor(progressRatio) {
    if (progressRatio === 1) {
        return 'white';
    }
    else {
        const red = progressRatio < 0.5 ? 255 : Math.floor((1 - progressRatio) * 2 * 255);
        const green = progressRatio < 0.5 ? Math.floor(progressRatio * 2 * 255) : 255;
        const blue = 0;
        return `rgb(${red}, ${green}, ${blue})`;
    }
}