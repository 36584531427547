import './ResetLearning.css';

import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '../Button/Button';
import { resetLearning } from '../../controllers/UserController';
import { useAuth0 } from "@auth0/auth0-react";

export default function ResetLearning() {
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const [text, setText] = useState();
    
    return (
        <div className='resetLearningDiv'>
            <div className='resetLearningForm'>
                <h1>Reset Learning</h1>
                <p>
                    This will reset all your learned words. Are you sure you want to do this? If so, please type 
                    <span> delete my learning data</span> into the text box below and press the <span>Confirm</span> button.
                </p>
                <input 
                    type="text"
                    name="textbox" 
                    placeholder="Type here..."
                    onChange={(e) => setText(e.target.value)}
                    autoComplete="off"/>
                <div className='resetLearningFormButtons'>
                    <Button 
                        text={'Cancel'}
                        onClick={() => {
                            navigate('/');
                        }}
                        color={'#333030'}
                        borderColor={'transparent'}
                        textColor={'#b8b8b8'}/>
                    <div className='resetButtonsSpacer'/>
                    <Button 
                        text={'Confirm'}
                        enabled={text?.toLowerCase()==='delete my learning data'}
                        onClick={async () => {
                            const accessToken = await getAccessTokenSilently();
                            if (accessToken) {
                                const resetSuccess = await resetLearning(accessToken, text);
                                if (resetSuccess) {
                                    navigate('/');
                                }
                            }
                        }}
                        color={'#333030'}
                        borderColor={'#ff0000'}
                        textColor={'#ff0000'}/>
                </div>
            </div>
        </div>
    );
}