import './LearningRateInfo.css';

import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '../Button/Button';

export default function LearningRateInfo() {
    const navigate = useNavigate();
    
    return (
        <div className='learningRateInfoDiv'>
            <h1>What is the Learning Rate?</h1>
            <p>
                We recognize that everyone learns differently and at different speeds. Some students will need to see vocabulary words more often 
                before the word is fully memorized. Some students may be able to see a word only a few times before they’ve got it. This web app 
                recognizes those differences and also allows students flexibility in their learning.
            </p>
            <p>
                By adjusting the Learning Rate slider, students can change how quickly (or slowly) they try to expand their vocabulary. By adjusting 
                the slider all the way to the left, students will need to respond correctly to each word in their vocabulary at least 20 times in a 
                row before they see any new words. By adjusting it all the way to the right, they will only need to answer correctly two times.
            </p>
            <h1>Strategies for Learning</h1>
            <p>
                There is no one right way to adjust the Learning Rate for all students. For example, let’s say you already have a decent Greek 
                vocabulary, you may want to adjust the slider to the far right and blaze through the first 1,000 words as fast as you can. But 
                after you are introduced to more rare words, you may wish to throttle back for better retention.
            </p>
            <p>
                For new students, or for those students who struggle with memorizing vocabulary, we recommend adjusting the Learning Rate more to the left. 
                If you find that seeing the same words too frequently feels redundant, then adjust it more to the right. Over time, you will find your own, 
                personal “sweet spot” that feels most comfortable for your learning style and preferences.
            </p>
            <div className='learningRateInfoBackButton'>
                <Button 
                    text={'Back'}
                    onClick={() => {
                        navigate('/');
                    }}
                    color={'#333030'}
                    borderColor={'#333030'}
                    textColor={'#b8b8b8'}
                    icon='back'/>
            </div>
        </div>
    );
}