export const percentFromLearnRate = (rate) => {
    // rate here is between 2 and 20. We want to output a value between 0.1 and 1.
    return ((rate - 20) / -20) + 0.1;
}
export const learnRateFromPercent = (percent) => {
    // percent here is between 0.1 and 1. We want to output a value between 2 and 20.
    return Math.round(20 - ((percent - 0.1) * 20));
}

export const sliderFormatter = (val) => {
    // return Math.floor(val*100) + '%';

    const percentNumb = Math.round(val * 10000) / 100;
    return percentNumb.toString() + '%';
}