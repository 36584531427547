import './DesktopTopBar.css';
import React from 'react';
import DesktopTopBarButton from './DesktopTopBarButton';
import { useNavigate, useLocation } from "react-router-dom";

function DesktopTopBar(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const isAdmin = props.user?.isAdmin ?? false;

    return (
        <div className='desktopTopBar'>
            <a href="/" className='logoDiv'>
                <img src="/favicon.ico" alt=""/>
                <span>Greek New Testament Vocab</span>
            </a>
            <div className='desktopTopBarButtons'>
                <DesktopTopBarButton 
                    name='Learning'
                    toggled={location.pathname === '/learning'}
                    onClick={() => navigate('/learning')}/>
                <DesktopTopBarButton 
                    name='Filters'
                    toggled={location.pathname === '/filter-by-book'}
                    onClick={() => navigate('/filter-by-book')}/>
                <DesktopTopBarButton 
                    name='Review List'
                    toggled={location.pathname === '/review-list'}
                    onClick={() => navigate('/review-list')}/>
                <DesktopTopBarButton 
                    name='About'
                    toggled={location.pathname === '/about'}
                    onClick={() => navigate('/about')}/>
                <DesktopTopBarButton 
                    name='Contact'
                    toggled={location.pathname === '/contact'}
                    onClick={() => navigate('/contact')}/>
                {isAdmin &&
                <DesktopTopBarButton 
                    name='Admin Page'
                    toggled={location.pathname.startsWith('/admin')}
                    onClick={() => navigate('/admin')}/>}
            </div>
        </div>
    );
}
export default DesktopTopBar;