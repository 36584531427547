
export const callApi = async (urlPath, method, accessToken, body) => {
    let apiUrl = 'http://localhost:8081';
    if (process.env.NODE_ENV !== 'development') {
        apiUrl = `${window.location.origin}/api`;
    }

    const params = { 
        crossDomain:true,
        method: method, 
        headers: new Headers({
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        })
    }

    if (body) {
        params.body = JSON.stringify(body);
    }

    const response = await fetch(`${apiUrl}/${urlPath}`, params);
    if (response.status !== 204 && response.status !== 404 && response.status !== 500) {
        const json = await response.json();
        return json;
    }
    else {
        return null;
    }
}

export const callVoidApi = async (urlPath, method, accessToken, body) => {
    let apiUrl = 'http://localhost:8081';
    if (process.env.NODE_ENV !== 'development') {
        apiUrl = `${window.location.origin}/api`;
    }

    const params = { 
        crossDomain:true,
        method: method, 
        headers: new Headers({
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        })
    }

    if (body) {
        params.body = JSON.stringify(body);
    }

    await fetch(`${apiUrl}/${urlPath}`, params);
}