import './Learning.css';

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import CustomSlider from '../Slider/Slider';
import Button from '../Button/Button';
import { getLearnedCount, getOverallAccuracy, getUser, 
    setLearnRate as putLearnRate, setIncludeProperNames as putIncludeProperNames } from '../../controllers/UserController';
import { debounce } from 'lodash';
import { useAuth0 } from "@auth0/auth0-react";
import { learnRateFromPercent, percentFromLearnRate, sliderFormatter } from '../../utils/LearningRateUtils';
import TopBar from '../TopBar/TopBar';
import ddgImg from '../../DDG.png';

function Learning(props) {
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [learnRate, setLearnRate] = useState(null);
    const [learnedCount, setLearnedCount] = useState(0);
    const [overallAccuracy, setOverallAccuracy] = useState(0);

    useEffect(() => {
        if (isAuthenticated) {
            fetchDataOnAuthentication();
        }
    }, [isAuthenticated])

    const fetchDataOnAuthentication = async () => {
        const accessToken = await getAccessTokenSilently();
        // This will create a new db object for the user if one does not already exist.
        const newUser = await getUser(accessToken);
        setLearnRate(newUser.learnRate);

        // Get stats
        const newLearnedCount = await getLearnedCount(accessToken);
        setLearnedCount(newLearnedCount);

        const newOverallAccuracy = await getOverallAccuracy(accessToken);
        const roundedAccPercent = Math.round(newOverallAccuracy * 10000) / 100 
        setOverallAccuracy(roundedAccPercent);
    }

    const learnRateSliderChange = useCallback((val) => {
        if (val != null) {
            const rate = learnRateFromPercent(val);
            setLearnRate(rate);
            putLearnRateDebounced(rate);
        }
    }, []);

    const putLearnRateDebounced = useCallback(debounce(async (val) => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            await putLearnRate(accessToken, val);

            // Refresh the learned word counts
            const newLearnedCount = await getLearnedCount(accessToken);
            setLearnedCount(newLearnedCount);
        }
    }, 200), []);

    return (
        <div>
            <TopBar user={props.user}/>
            <div className='mainPageContent'>
                <div className='learning'>
                    <span className = 'statLabel'>Total Words Learned: <span className='statNumber'>{learnedCount}</span></span>
                    <span className = 'statLabel'>Overall Accuracy: <span className='statNumber'>{overallAccuracy}%</span></span>
                    <div className='learningRateSlider'>
                        <CustomSlider
                            label={'Learning Rate'}
                            min={0.1}
                            max={1}
                            step={0.05}
                            value={percentFromLearnRate(learnRate)}
                            onChange={learnRateSliderChange}
                            renderTooltip={sliderFormatter}
                            style={{
                                maxWidth: '16em'
                            }}
                            visible={learnRate != null}
                            infoButtonPath={'/learning-rate-info'}/>
                    </div>
                    <div className='settingsButtonDiv'>
                        <Button 
                            text={'Settings'}
                            onClick={() => navigate('/settings')}
                            color={'#333030'}
                            borderColor={'#333030'}
                            textColor={'#B3B3B3'}
                            icon={'settings'}/>
                    </div>
                    <div className='learnButtonDiv'>
                        <button 
                            className='learnButton'
                            onClick={() => navigate('/learn')}>
                            <span>Start Learning</span>
                        </button>
                    </div>
                    <div className='ddog'>
                        <a href='https://globalservicenetwork.org/give/30815001' target="_blank">
                            <img 
                                src={ddgImg}
                                className='ddgImg'
                                width={190}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Learning;