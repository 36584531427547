import React from 'react';

export default function CheckBox(props) {

    const checked = props.checked;
    const setChecked = props.setChecked;
    const text = props.text;
    const textColor = props.textColor ?? 'white';

    const checkedIconStyle = {
        backgroundColor: '#1677ff',
        borderColor: 'transparent'
    };

    return (
        <div 
            className={'checkBox'}
            onClick={() => setChecked(!checked)}>
            <div className={'checkBoxIcon'} style={checked ? checkedIconStyle : {}}>
                {checked &&
                <div className='check'/>}
            </div>
            <div className={'checkBoxText'}>
                <span style={{color: textColor}}>
                    {text}
                </span>
            </div>
        </div>
    );
}