import { useAuth0 } from '@auth0/auth0-react';
import './EditGrammars.css';
import React, { useEffect, useState } from 'react';
import Spinner from '../../Spinner';
import { deleteGrammar, deleteGrammarChapter, deleteGrammarChapterLemma, getGrammarChapterLemmas, getGrammarChapters, getGrammars } from '../../../controllers/GrammarController';
import Button from '../../Button/Button';
import Popup from '../../Popup/Popup';
import ImportForm from './ImportPopup/ImportForm';
import { write, utils } from 'xlsx';
import ConfirmDelete from './ConfirmDelete/ConfirmDelete';
import toast, { Toaster } from 'react-hot-toast';

function EditGrammars() {
    const { getAccessTokenSilently } = useAuth0();

    const [grammars, setGrammars] = useState(undefined);
    const [importFormOpen, setImportFormOpen] = useState(false);

    const [selectedGrammar, setSelectedGrammar] = useState(undefined);
    const [selectedGrammarChapters, setSelectedGrammarChapters] = useState(undefined);
    const [selectedGrammarChapter, setSelectedGrammarChapter] = useState(undefined);
    const [selectedGrammarChapterLemmas, setSelectedGrammarChapterLemmas] = useState(undefined);

    const [gramSearchText, setGramSearchText] = useState('');  
    const [chapSearchText, setChapSearchText] = useState(''); 
    const [lemmasSearchText, setLemmasSearchText] = useState('');  

    const [deleteRequest, setDeleteRequest] = useState(null);

    const fetchData = async () => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            const newGrammars = await getGrammars(accessToken);
            setGrammars(newGrammars);

            if (newGrammars?.length > 0 && selectedGrammar == null) {
                setSelectedGrammar(newGrammars[0]);
            }
        }
    }

    const fetchSelectedChapters = async () => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken && selectedGrammar != null) {
            const newGrammarChapters = await getGrammarChapters(accessToken, selectedGrammar.id);
            setSelectedGrammarChapters(newGrammarChapters);

            if (newGrammarChapters?.length > 0) {
                setSelectedGrammarChapter(newGrammarChapters[0]);
            }
        }
    }

    const fetchSelectedChapterLemmas = async () => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken && selectedGrammar != null && selectedGrammarChapter != null) {
            const newGrammarChapterLemmas = await getGrammarChapterLemmas(accessToken, selectedGrammar.id, selectedGrammarChapter.id);
            setSelectedGrammarChapterLemmas(newGrammarChapterLemmas);
        }
    }

    // Grab a new grammar when the component first loads
    useEffect(() => {
        fetchData();
    }, []);

    // Grab grammar chapters on grammar selection
    useEffect(() => {
        setSelectedGrammarChapter(undefined);
        setSelectedGrammarChapters(undefined);

        fetchSelectedChapters();
    }, [selectedGrammar]);

    // Grab grammar chapter lemmas on grammar chapter selection
    useEffect(() => {
        setSelectedGrammarChapterLemmas(undefined);
        fetchSelectedChapterLemmas();
    }, [selectedGrammarChapter]);

    const deleteExistingGrammar = async (id) => { 
        // Delete from UI
        if (selectedGrammar?.id === id) {
            setSelectedGrammar(undefined);
        }  

        // Delete from DB
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            deleteGrammar(accessToken, id).then(success => {
                if (!success) {
                    console.error('Failed to delete grammar.');
                    toast.error('Failed to delete grammar.');
                }
                else {
                    fetchData();
                    toast.success('Deleted grammar successfully.');
                }
            });
        }
    }

    const deleteExistingGrammarChapter = async (chapterId) => { 
        // Delete from UI
        if (selectedGrammarChapter?.id === chapterId) {
            setSelectedGrammarChapter(undefined);
        }  

        // Delete from DB
        const accessToken = await getAccessTokenSilently();
        if (accessToken && selectedGrammar != null && selectedGrammarChapters != null) {
            deleteGrammarChapter(accessToken, selectedGrammar.id, chapterId).then(success => {
                if (!success) {
                    console.error('Failed to delete chapter.');
                    toast.error('Failed to delete chapter.');
                }
                else {
                    fetchSelectedChapters();
                    toast.success('Deleted chapter successfully.');
                }
            });
        }
    }

    const deleteExistingGrammarChapterLemma = async (lemmaId) => { 
        // Delete from DB
        const accessToken = await getAccessTokenSilently();
        if (accessToken && selectedGrammar != null && selectedGrammarChapter != null) {
            deleteGrammarChapterLemma(accessToken, selectedGrammar.id, selectedGrammarChapter.id, lemmaId).then(success => {
                if (!success) {
                    console.error('Failed to delete chapter lemma.');
                    toast.error('Failed to delete chapter lemma.');
                }
                else {
                    fetchSelectedChapterLemmas();
                    toast.success('Removed chapter lemma successfully.');
                }
            });
        }
    }

    const exportGrammar = async (grammar) => {
        const workbook = utils.book_new();
        const grammarSheet = utils.json_to_sheet([{
            name: grammar.name
        }]);
        const chaptersSheet = utils.json_to_sheet(grammar.chapters ?? [{
            id: null,
            name: null
        }]);
        const lemmasSheet = utils.json_to_sheet(grammar.lemmas ?? [{
            chapterId: null,
            lemmaId: null
        }]);

        utils.book_append_sheet(workbook, grammarSheet, 'Grammar');
        utils.book_append_sheet(workbook, chaptersSheet, 'Chapters');
        utils.book_append_sheet(workbook, lemmasSheet, 'Lemmas');

        const wbout = write(workbook, { bookType: 'xlsx', type: 'binary' });
        const fileName = `${grammar.name}.xlsx`;
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    const importConfirmedCallback = (changesWereMade) => {
        setImportFormOpen(false);
        
        if (changesWereMade) {
            fetchData();
        }
    }

    return (
        <div className='grammars'>
            <div className='grammarPageLists'>
                <div className='grammarsPageList grammarsList'>
                    <div className='grammarsPageHeader'>
                        <h1>Grammars</h1>
                        <Button 
                            text={'Get Import Template'} 
                            textColor={'goldenrod'}
                            onClick={() => {
                                exportGrammar({
                                    name: 'Grammar Template',
                                    chapters: [{
                                        id: 1,
                                        name: 'Chap1'
                                    },
                                    {
                                        id: 2,
                                        name: 'Chap2'
                                    }],
                                    lemmas: [{
                                        chapterId: 1,
                                        lemmaId: 'Lemma1'
                                    },
                                    {   
                                        chapterId: 2,
                                        lemmaId: 'Lemma2'
                                    }]
                                });
                            }}/>
                        <Button 
                            text={'Import New'} 
                            textColor={'goldenrod'}
                            onClick={() => {
                                setImportFormOpen(true);
                            }}/>
                    </div>
                    <input 
                        type="text" 
                        value={gramSearchText} 
                        onChange={(e) => {
                            const newVal = e.target.value;
                            setGramSearchText(newVal);
                        }}
                        placeholder="Search for a grammars..."/>
                    <Spinner show={grammars == null}/>
                    {grammars != null && grammars.length > 0 &&
                    <div className='grammarsPageListContent grammarsListContent'>
                        {grammars?.filter(i => searchTermMatch(i.name, gramSearchText))
                            .map((grammar, index) => {
                            return (
                                <div 
                                    className={'grammarListItem'} 
                                    key={index}>
                                    <div 
                                        className={getGrammarContentClass(selectedGrammar?.id === grammar.id)}
                                        onClick={() => setSelectedGrammar(grammar)}>
                                        <div className='grammarName'>{grammar.name}</div>
                                        <div className='grammarListItemRow'>
                                            <div className='grammarListItemSubRow'>
                                                <div className='grammarChapters'>{grammar.chapterCount ?? 0} Chapters</div>
                                                <div className='grammarLemmas'>{grammar.lemmaCount ?? 0} Lemmas</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='grammarListItemActionRow'>
                                        <Button 
                                            text={'Delete'} 
                                            textColor={'red'}
                                            onClick={() => {
                                                setDeleteRequest({
                                                    action: () => deleteExistingGrammar(grammar.id),
                                                    message: `Are you sure you want to delete the grammar "${grammar.name}"?`
                                                });
                                            }}/>
                                    </div>
                                </div>
                            );
                        })}
                    </div>}
                    {grammars?.length === 0 &&
                    <div className='grammarsPageListContentEmptyMessage'>
                        No grammars to show. Import a grammar to get started.
                    </div>}
                </div>
                <div className='grammarsPageList grammarChapterList'>
                    <div className='grammarsPageHeader'>
                        <h1>Grammar Chapters</h1>
                    </div>
                    <input 
                        type="text" 
                        value={chapSearchText} 
                        onChange={(e) => {
                            const newVal = e.target.value;
                            setChapSearchText(newVal);
                        }}
                        placeholder="Search for a chapters..."/>
                    <Spinner show={selectedGrammar != null && selectedGrammarChapters == null}/>
                    {selectedGrammar != null && selectedGrammarChapters?.length > 0 &&
                    <div className='grammarsPageListContent grammarChapterListContent'>
                        {selectedGrammarChapters?.filter(i => searchTermMatch(i.name, chapSearchText))
                            .map((chapter, index) => (
                            <div 
                                key={`chapterItem${index}`}
                                className={'grammarListItem'}>
                                <div 
                                    className={getGrammarContentClass(selectedGrammarChapter?.id === chapter.id)}
                                    onClick={() => setSelectedGrammarChapter(chapter)}>
                                    <div className='grammarName'>{chapter.name}</div>
                                    <div className='grammarListItemRow'>
                                        <div className='grammarListItemSubRow'>
                                            <div className='grammarLemmas'>{chapter.lemmaCount ?? 0} Lemmas</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='grammarListItemActionRow'>
                                    <Button 
                                        text={'Delete'} 
                                        textColor={'red'}
                                        onClick={() => {
                                            setDeleteRequest({
                                                action: () => deleteExistingGrammarChapter(chapter.id),
                                                message: `Are you sure you want to delete the chapter "${chapter.name}" from the selected grammar?`
                                            });
                                        }}/>
                                </div>
                            </div>
                        ))}
                    </div>}
                    {selectedGrammar == null &&
                    <div className='grammarsPageListContentEmptyMessage'>
                        No grammar selected. Select a grammar to view its chapters.
                    </div>}
                </div>
                <div className='grammarsPageList chapterLemmaList'>
                    <div className='grammarsPageHeader'>
                        <h1>Chapter Lemmas</h1>
                    </div>
                    <input 
                        type="text" 
                        value={lemmasSearchText} 
                        onChange={(e) => {
                            const newVal = e.target.value;
                            setLemmasSearchText(newVal);
                        }}
                        placeholder="Search for a lemma..."/>
                    <Spinner show={selectedGrammar != null && selectedGrammarChapter != null && selectedGrammarChapterLemmas == null}/>
                    {selectedGrammar != null && selectedGrammarChapter != null && selectedGrammarChapterLemmas?.length > 0 &&
                    <div className='grammarsPageListContent chapterLemmaListContent'>
                        {selectedGrammarChapterLemmas?.filter(
                            i => searchTermMatch(i.word, lemmasSearchText) 
                            || searchTermMatch(i.definition, lemmasSearchText))
                            .map((lemma, index) => (
                            <div 
                                key={`chapterLemmaItem${index}`}
                                className='grammarListItem'>
                                <div className='lemmaListItemContent'>
                                    <div className='grammarName'>{lemma.word}</div>
                                    <div className='grammarListItemRow'>
                                        <div className='grammarListItemSubRow'>
                                            <div className='grammarLemmas'>{lemma.definition}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='grammarListItemActionRow'>
                                    <Button 
                                        text={'Remove'} 
                                        textColor={'red'}
                                        onClick={() => {
                                            setDeleteRequest({
                                                action: () => deleteExistingGrammarChapterLemma(lemma.id),
                                                message: `Are you sure you want to remove the lemma "${lemma.word}" from the selected chapter?`
                                            });
                                        }}/>
                                </div>
                            </div>
                        ))}
                    </div>}
                    {selectedGrammar != null && selectedGrammarChapter == null &&
                    <div className='grammarsPageListContentEmptyMessage'>
                        No chapter selected. Select a chapter to view its lemmas.
                    </div>}
                </div>
            </div>
            <Popup 
                isOpen={importFormOpen} 
                onClose={() => setImportFormOpen(false)} 
                title={'Import Grammar'}
                content={
                    <ImportForm
                        importConfirmed={importConfirmedCallback}/>
                    }
                customPopupClass={''}/>
            <Popup 
                isOpen={deleteRequest != null} 
                onClose={() => setDeleteRequest(null)} 
                title={'Confirm Delete'}
                content={
                    <ConfirmDelete
                        onConfirm={() => {
                            deleteRequest.action();
                            setDeleteRequest(null);
                        }}
                        onCancel={() => {
                            setDeleteRequest(null);
                        }}
                        message={deleteRequest?.message}/>
                    }
                customPopupClass={'confirmDeletePopup'}/>
            <Toaster position='bottom-right'/>
        </div> 
    );
}
export default EditGrammars;

function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
}

function getGrammarContentClass(isSelected) {
    return isSelected ? `grammarListItemContent selectedGrammarListItemContent` : 'grammarListItemContent'
}

function searchTermMatch(a, b) {
    return a.toLowerCase().includes(b.toLowerCase());
}