import './DesktopLearning.css';

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import CustomSlider from '../Slider/Slider';
import Switch from '../Switch/Switch';
import Button from '../Button/Button';
import { getLearnedCount, getOverallAccuracy, getUser, 
    setLearnRate as putLearnRate, setIncludeProperNames as putIncludeProperNames, putPlaySounds } from '../../controllers/UserController';
import { debounce } from 'lodash';
import { useAuth0 } from "@auth0/auth0-react";
import { learnRateFromPercent, percentFromLearnRate, sliderFormatter } from '../../utils/LearningRateUtils';
import ddgImg from '../../DDG.png';

function DesktopLearning(props) {
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [learnRate, setLearnRate] = useState(null);
    const [learnedCount, setLearnedCount] = useState(0);
    const [overallAccuracy, setOverallAccuracy] = useState(0);
    const [includeProperNames, setIncludeProperNames] = useState(null);
    const [playSounds, setPlaySounds] = useState(null);

    useEffect(() => {
        if (isAuthenticated) {
            fetchDataOnAuthentication();
        }
    }, [isAuthenticated])

    const fetchDataOnAuthentication = async () => {
        const accessToken = await getAccessTokenSilently();
        // This will create a new db object for the user if one does not already exist.
        const newUser = await getUser(accessToken);
        setLearnRate(newUser.learnRate);
        setIncludeProperNames(newUser.includeProperNames);
        setPlaySounds(newUser.playSounds);

        // Get stats
        const newLearnedCount = await getLearnedCount(accessToken);
        setLearnedCount(newLearnedCount);

        const newOverallAccuracy = await getOverallAccuracy(accessToken);
        const roundedAccPercent = Math.round(newOverallAccuracy * 10000) / 100 
        setOverallAccuracy(roundedAccPercent);
    }

    const learnRateSliderChange = useCallback((val) => {
        if (val != null) {
            const rate = learnRateFromPercent(val);
            setLearnRate(rate);
            putLearnRateDebounced(rate);
        }
    }, []);

    const putLearnRateDebounced = useCallback(debounce(async (val) => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            await putLearnRate(accessToken, val);

            // Refresh the learned word counts
            const newLearnedCount = await getLearnedCount(accessToken);
            setLearnedCount(newLearnedCount);
        }
    }, 200), []);

    const getIncludeProperNamesSwitchUI = () => {
        if (includeProperNames != null) {
            return (
                <Switch 
                    enabled={includeProperNames} 
                    setEnabled={newVal => includeProperNamesValueChanged(newVal)}
                    text={'Include Proper Names'}
                    textColor={'rgb(179, 179, 179)'}/>
            );
        }
        else {
            return <div/>
        }
    };

    const getPlaySoundSwitchUI = () => {
        if (playSounds != null) {
            return (
                <Switch 
                    enabled={playSounds} 
                    setEnabled={newVal => playSoundsValueChanged(newVal)}
                    text={'Play Sound Effects'}
                    textColor={'rgb(179, 179, 179)'}/>
            );
        }
        else {
            return <div/>
        }
    };

    const includeProperNamesValueChanged = async (val) => {
        // Change UI
        setIncludeProperNames(val);
        
        // Change the value in the database
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            await putIncludeProperNames(accessToken, val);
        }
    };

    const playSoundsValueChanged = async (val) => {
        // Change UI
        setPlaySounds(val);
        
        // Change the value in the database
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            await putPlaySounds(accessToken, val);
        }
    };

    const getResetButtonUI = () => {
        const resetButtonClicked = () => {
            navigate("/reset-learning");
        }

        return (
            <Button 
                text={'Reset Learning'}
                onClick={resetButtonClicked}
                color={'#333030'}
                borderColor={'transparent'}
                textColor={'#ff7300'}/>
        );
    }

    return (
        <div className='desktopLearning'>
            <span className = 'statLabel'>Total Words Learned: <span className='statNumber'>{learnedCount}</span></span>
            <span className = 'statLabel'>Overall Accuracy: <span className='statNumber'>{overallAccuracy}%</span></span>
            <div className='learningRateSlider'>
                <CustomSlider
                    label={'Learning Rate'}
                    min={0.1}
                    max={1}
                    step={0.05}
                    value={percentFromLearnRate(learnRate)}
                    onChange={learnRateSliderChange}
                    renderTooltip={sliderFormatter}
                    style={{
                        maxWidth: '25em'
                    }}
                    visible={learnRate != null}
                    infoButtonPath={'/learning-rate-info'}/>
            </div>
            <div className='settingsButtonDiv'>
                <Button 
                    text={'Settings'}
                    onClick={() => navigate('/settings')}
                    color={'#333030'}
                    borderColor={'#333030'}
                    textColor={'#B3B3B3'}
                    icon={'settings'}/>
            </div>
            <div className='learnButtonDiv'>
                <button 
                    className='learnButton'
                    onClick={() => navigate('/learn')}>
                    <span>Start Learning</span>
                </button>
            </div>
            <div className='ddog'>
                <a href='https://globalservicenetwork.org/give/30815001' target="_blank">
                    <img 
                        src={ddgImg}
                        className='ddgImg'
                        width={280}/>
                </a>
            </div>
        </div>
    );
}
export default DesktopLearning;