import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { reviewLemma as putReview } from '../controllers/LessonController';
import dingRight from '../sounds/right.mp3';
import dingWrong from '../sounds/wrong.mp3';
import { findLongestWordLength } from './LessonViewUtils';
import { getProgressColor } from './BookSelector.jsx';
const audioContext = new window.AudioContext();

export default function LessonView(props) {

    const { getAccessTokenSilently } = useAuth0();

    const [lemmaToLearn, setLemmaToLearn] = useState(props.lesson.lemmasToLearn[0]);
    const [lemmaToGuess, setLemmaToGuess] = useState(null);

    const lemmaToLearnIndex = useRef(0);
    const lemmaToGuessIndex = useRef(0);

    const rightAudioBuffer = useRef(null);
    const wrongAudioBuffer = useRef(null);

    // A hack to see which answers are right for testing purposes.
    const [xRayVision, setXRayVision] = useState(false);

    useEffect(() => {
        fetch(dingRight)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
            .then(audioBuffer => {
                rightAudioBuffer.current = audioBuffer;
            })
            .catch(e => console.error(e));
        fetch(dingWrong)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
            .then(audioBuffer => {
                wrongAudioBuffer.current = audioBuffer;
            })
            .catch(e => console.error(e));
    }, []);

    useEffect(() => {
        if (lemmaToGuess == null && lemmaToLearn == null) {
            // Just load new lesson once current lesson is finished.
            props.newLesson();
        }

        const handleKeyDown = (event) => {
            if ((event.keyCode === 13 || event.keyCode === 32) && lemmaToLearn) {
                gotIt();
            }
            else if (event.keyCode === 27) {
                props.endLesson();
            }
            else if (event.keyCode >= 49 && event.keyCode <= 56) {
                if (lemmaToGuess != null && lemmaToLearn == null) {
                    let guessIndex = event.keyCode - 49;
                    const opt = lemmaToGuess.options[guessIndex];
                    chooseOption(opt);
                }
            }
            // Numpad codes are different
            else if (event.keyCode >= 97 && event.keyCode <= 104) {
                if (lemmaToGuess != null && lemmaToLearn == null) {
                    let guessIndex = event.keyCode - 97;
                    const opt = lemmaToGuess.options[guessIndex];
                    chooseOption(opt);
                }
            }
            // If user presses "`" activate xray mode
            else if (event.keyCode == 192) {
                setXRayVision(!xRayVision);
            }
        };
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [lemmaToGuess, lemmaToLearn, xRayVision]);

    useEffect(() => {
        // Reset the lesson state when a new lesson is loaded from props.
        lemmaToLearnIndex.current = 0;
        lemmaToGuessIndex.current = 0;
        if (props.lesson.lemmasWithZeroStreak?.length > 0) {
            setLemmaToLearn(props.lesson.lemmasWithZeroStreak[0]);
            setLemmaToGuess(null);
        }
        else {
            setLemmaToLearn(null);
            setLemmaToGuess(props.lesson.lemmasToGuess[0]);
        }
    }, [props.lesson])

    const gotIt = () => {
        const zeroStreakLemmas = props.lesson.lemmasWithZeroStreak;
        const lemmasToGuess = props.lesson.lemmasToGuess;
        const index = lemmaToLearnIndex.current;

        if (zeroStreakLemmas.length > index + 1) {
            setLemmaToLearn(zeroStreakLemmas[index + 1]);
            lemmaToLearnIndex.current = index + 1;
        }
        else {
            setLemmaToLearn(null);

            // Set the first lemma to guess if guessing has not started yet.
            if (lemmaToGuess == null)
                setLemmaToGuess(lemmasToGuess[0]);
        }
    }

    const chooseOption = (option) => {
        const lemmasToGuess = props.lesson.lemmasToGuess;
        const totalLemmasToGuess = lemmasToGuess.length ?? 0;
        const index = lemmaToGuessIndex.current;

        if (option.word == lemmaToGuess.lemma.word) {
            reviewLemma(lemmaToGuess.lemma.id, true);
        }
        else {
            setLemmaToLearn(lemmaToGuess.lemma);
            reviewLemma(lemmaToGuess.lemma.id, false);
        }

        if (totalLemmasToGuess > index + 1) {
            setLemmaToGuess(lemmasToGuess[index + 1]);
            lemmaToGuessIndex.current = index + 1;
        }
        else {
            setLemmaToGuess(null);
            lemmaToGuessIndex.current = totalLemmasToGuess - 1;
        }
    }

    const reviewLemma = useCallback(async (lemmaId, correct) => {
        // Make a ding sound!
        const playSound = (buffer) => {
            if (!buffer) return;

            const source = audioContext.createBufferSource();
            source.buffer = buffer;
            source.connect(audioContext.destination);
            source.start();
        }

        if (props.lesson.playSounds) {
            playSound(correct ? rightAudioBuffer.current : wrongAudioBuffer.current);
        }

        // Actually record the review
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            putReview(accessToken, lemmaId, correct);
        }
    }, [props.lesson.playSounds]);

    const backButton = (
        <a id='lessonViewBackButton' href='/#/learning'>
            ← Back to home
        </a>
    );

    const lemmaStatusIndicator = (percentLearned) => {
        const pieDegrees = (percentLearned/100) * 360;
        const pieColor = getProgressColor(percentLearned/100);
        if (percentLearned === 0) {
            return <div/>;
        }
        else if (percentLearned === 100) {
            return (
                <div id='learnedStatus'>
                    Learned
                </div>
            );
        }
        return (
            <div id='learnedStatus'>
                Learned status
                <div 
                    id='learnedStatusPie'
                    style={{
                        background: `conic-gradient(${pieColor} ${pieDegrees}deg,gray ${pieDegrees}deg)`
                    }}>
                </div>
            </div>
        )
    };

    const textToLearn = lemmaToLearn?.word;
    const textToGuess = lemmaToGuess?.lemma.word;

    return (
        <div id="lessonViewDiv">
            {lemmaToLearn &&
            <div id="learnCard" onClick={gotIt}>
                {backButton}
                <h1 style={{fontSize: getHeaderTextFontSize(textToLearn) + 'em'}}>
                    {textToLearn}
                </h1>
                <div id='learnCardBody'>
                    <p>
                        {lemmaToLearn.definition}
                    </p>
                    <div className='gotItText'>
                        <span>tap on this card to continue</span>
                    </div>
                </div>
            </div>}
            {lemmaToGuess && !lemmaToLearn &&
            <div id="guessCard">
                <div className='guessCardHeader'>
                    {backButton}
                    {lemmaStatusIndicator(lemmaToGuess.percentLearned)}
                </div>
                <h1 style={{fontSize: getHeaderTextFontSize(textToGuess) + 'em'}}>
                    {textToGuess}
                </h1>
                <div className={'guessGrid'}>
                    {lemmaToGuess.options.map((option, index) => {
                        let defStyle = {};
                        if (xRayVision && lemmaToGuess.lemma.definition === option.definition) {
                            defStyle.color = 'gold';
                        }
                        return (
                        <div 
                            className='guessGridItem'
                            key={index} 
                            onClick={() => chooseOption(option)}>
                            <p style={defStyle}>
                                {index + 1}. {option.definition}
                            </p>
                        </div>
                    )})}
                </div>
            </div>}
        </div>
    );
}

function getHeaderTextFontSize(text) {
    const minHeaderTextSizeInEm = 2.2;
    const maxHeaderTextSizeInEm = 3.8;
    
    const longestWordLength = findLongestWordLength(text);
    const totalChars = text.length;

    // If the total number of chars is large, just use the small font.
    if (totalChars > 24) {
        return minHeaderTextSizeInEm;
    }

    // Longest single word: τεσσαρεσκαιδέκατος -> 18 chars
    // y = -0.2x + 5.8 <- the slope of the line if we assume 18 chars is 2.2 and 10 chars is 3.8
    const headerTextSizeInEm = Math.max(minHeaderTextSizeInEm, Math.min(maxHeaderTextSizeInEm, (-0.2 * longestWordLength) + 5.8));

    return headerTextSizeInEm;
}