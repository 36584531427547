import React from 'react';
import './Button.css';
import settingsImg from '../../icons/settings.png';
import backImg from '../../icons/back.png';

export default function Button(props) {

    const onClick = props.onClick;
    const enabled = props.enabled ?? true;
    const text = props.text;
    const textColor = props.textColor ?? 'white';
    const color = props.color ?? 'black';
    const borderColor = props.borderColor ?? 'black';
    const style = props.style;

    const buttonStyle = {
        backgroundColor: color,
        borderColor: borderColor,
        ...style
    };

    const textStyle = {
        color: textColor
    };

    // Show a disabled button here if needed
    if (!enabled) {
        return (
            <div 
                className={'button disabledButton'}
                style={buttonStyle}>
                {props.icon === 'settings' && <img src={settingsImg}/>}
                {props.icon === 'back' && <img src={backImg}/>}
                <span style={textStyle}>
                    {text}
                </span>
            </div>
        );
    }

    return (
        <div 
            className={'button enabledButton'}
            onClick={onClick}
            style={buttonStyle}>
            {props.icon === 'settings' && <img src={settingsImg}/>}
            {props.icon === 'back' && <img src={backImg}/>}
            <span style={textStyle}>
                {text}
            </span>
        </div>
    );
}