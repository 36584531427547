import './DesktopBooks.css';

import React, { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from '../Spinner';
import { getBooks } from '../../controllers/BookController';
import BookSelector from '../BookSelector.jsx';
import SelectBox from '../SelectBox/SelectBox';
import { getGrammarChapters, getGrammars } from '../../controllers/GrammarController';
import GrammarChapterSelector from '../GrammarChapterSelector';
import { getDbSelectedBookChapters, getDbSelectedBookId, getSelectedFilterType, getSelectedGrammarId, putSelectedFilterType, putSelectedGrammarId, setDbSelectedBookId } from '../../controllers/FilterController';
import BookChapterSelector from '../BookChapterSelector';

function DesktopBooks(props) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [books, setBooks] = useState([]);
    const [bookChapters, setBookChapters] = useState([]);
    const [grammars, setGrammars] = useState(null);
    const [grammarChapters, setGrammarChapters] = useState(null);

    const [filterType, setFilterType] = useState(0);

    // A zero id means no grammar is selected.
    const [selectedGrammarId, setSelectedGrammarId] = useState(null);
    const [selectedBookId, setSelectedBookId] = useState(null);

    const filterTypeOptions = useMemo(() => ([
        {
            value: 0,
            label: 'Book'
        }, {
            value: 1,
            label: 'Chapter'
        }, {
            value: 2,
            label: 'Grammar'
        }
    ]), []);

    const grammarOptions = useMemo(() => {
        let newOptions = [];
        if (grammars != null) {
            newOptions = [...newOptions, ...grammars.map(g => {
                return {
                    value: g.id,
                    label: g.name
                }
            })];
        }
        return newOptions;
    }, [grammars]);

    useEffect(() => {
        if (isAuthenticated) {
            getAccessTokenSilently().then(accessToken => {
                getSelectedFilterType(accessToken).then(result => {
                    setFilterType(result);
                });
            });
        }
    }, [isAuthenticated]);

    useEffect(() => {
        const doAsyncWork = async () => {
            const accessToken = await getAccessTokenSilently();
            if (accessToken != null) {
                switch (filterType) {
                    case 0:
                        const books = await getBooks(accessToken);
                        setBooks(books);
                        break;
                    case 1:
                        const selectedBookId = await getDbSelectedBookId(accessToken);
                        
                        // If no book is selected, select the first book
                        if (!selectedBookId) {
                            const books = await getBooks(accessToken);
                            setBooks(books);

                            if (books && books.length > 0) {
                                const firstBook = books[0];
                                await onSelectedBookIdChange(firstBook.id);
                            }
                        }
                        else {
                            setSelectedBookId(selectedBookId);
                        }

                        const bookChapters = await getDbSelectedBookChapters(accessToken);
                        setBookChapters(bookChapters);

                        break;
                    case 2:
                        const grammars = await getGrammars(accessToken);
                        setGrammars(grammars);

                        const selectedGrammarId = await getSelectedGrammarId(accessToken);

                        // If no grammar is selected, select the first grammar
                        if (!selectedGrammarId && grammars && grammars.length > 0) {
                            const firstGrammar = grammars[0];
                            await onSelectedGrammarIdChange(firstGrammar.id);
                        }
                        else {
                            setSelectedGrammarId(selectedGrammarId);
                        }
                        break;
                }
            }
        }
        doAsyncWork();
    }, [filterType]);

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            if (accessToken != null && selectedGrammarId != null && selectedGrammarId != 0) {
                const newGrammarChapters = await getGrammarChapters(accessToken, selectedGrammarId);
                setGrammarChapters(newGrammarChapters);
            }
            else {
                setGrammarChapters([]);
            }
        }
        fetchData();
    }, [selectedGrammarId]);

    const setDbSelectedFilterType = async (newVal) => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken != null) {
            putSelectedFilterType(accessToken, newVal);
        }
    }

    const onSelectedBookIdChange = async (newVal) => {
        setSelectedBookId(newVal);

        // Make the change in the db
        const accessToken = await getAccessTokenSilently();
        if (accessToken != null) {
            await setDbSelectedBookId(accessToken, newVal);

            // Update the book chapters list
            const selectedBookChapters = await getDbSelectedBookChapters(accessToken);
            setBookChapters(selectedBookChapters);
        }
    }

    const onSelectedGrammarIdChange = async (newVal) => {
        setSelectedGrammarId(newVal);
        
        // Make the change in the db
        const accessToken = await getAccessTokenSilently();
        if (accessToken != null) {
            await putSelectedGrammarId(accessToken, newVal);
        }
    }

    if (!books || books.length < 27) {
        return (
            <div style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '5em',
                marginBottom: '5em'
            }}>
                <Spinner show={true}/>
            </div>   
        )
    }

    // Dynamically set book chapter columns based on the number of chapters
    const bookChapterCount = bookChapters?.length ?? 0;

    const twoColNum = 8;
    const threeColNum = 14;

    let bookChapterColumns = 1;
    if (bookChapterCount >= twoColNum && bookChapterCount < threeColNum) {
        bookChapterColumns = 2;
    }
    else if (bookChapterCount >= threeColNum) {
        bookChapterColumns = 3;
    }

    return (
        <div className='desktopBooksList'>
            <div className='desktopFilterInputBoxDiv'>
                <div className='desktopOuterFilterInputBox'>
                    <label>Filter by</label>
                    <SelectBox
                        options={filterTypeOptions}
                        value={filterType}
                        onChange={(newVal) => {
                            setFilterType(Number(newVal));
                            setDbSelectedFilterType(newVal);
                        }}/>
                </div>
                {filterType !== 0 &&
                <div className='desktopInnerFilterInputBox'>
                    {filterType === 1 &&
                    <SelectBox
                        options={books.map(b => {
                            return {
                                value: b.id,
                                label: b.name
                            }
                        })}
                        value={selectedBookId ?? 0}
                        onChange={onSelectedBookIdChange}/>}
                    {filterType === 2 &&
                    <SelectBox
                        options={grammarOptions}
                        value={selectedGrammarId ?? 0}
                        onChange={onSelectedGrammarIdChange}/>}
                </div>}
            </div>
            {filterType == 0 &&
            <ul>
                {books.map((b, index) => 
                <li key={index}>
                    <BookSelector 
                        book={b} 
                        setSelected={(val) => {
                            const newBooks = books.map(book => {
                                if (book.id === b.id) {
                                    return {
                                        ...book,
                                        isSelected: val
                                    }
                                }
                                return book;
                            });
                            setBooks(newBooks);
                        }} 
                        key={b.sbl}/>
                </li>)}
            </ul>}
            {filterType === 1 && 
            <ul style={{columns: bookChapterColumns}}>
                {bookChapters.map((chapter, index) =>
                <li key={index}>
                    <BookChapterSelector 
                        bookId={selectedBookId} 
                        chapter={chapter} 
                        key={index}
                        setSelected={(val) => {
                            const newBookChapters = bookChapters.map(bookChapter => {
                                if (bookChapter.id === chapter.id) {
                                    return {
                                        ...bookChapter,
                                        isSelected: val
                                    }
                                }
                                return bookChapter;
                            });
                            setBookChapters(newBookChapters);
                        }}/>
                </li>)}
            </ul>}
            {filterType === 2 && selectedGrammarId != null && selectedGrammarId != 0 && grammarChapters != null &&
            <div className='desktopGrammarsList'>
                <ul>
                    {grammarChapters.map((chapter, index) => 
                    <li key={index}>
                         <GrammarChapterSelector 
                            grammarId={selectedGrammarId} 
                            chapter={chapter} 
                            key={index}
                            setSelected={(val) => {
                                const newGrammarChapters = grammarChapters.map(grammarChapter => {
                                    if (grammarChapter.id === chapter.id) {
                                        return {
                                            ...grammarChapter,
                                            isSelected: val
                                        }
                                    }
                                    return grammarChapter;
                                });
                                setGrammarChapters(newGrammarChapters);
                            }}/>
                    </li>)}
                </ul>
            </div>}
        </div>
    );
}
export default DesktopBooks;