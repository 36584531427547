import './Maintenance.css';

import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '../../Button/Button';
import CheckBox from '../../CheckBox';
import { addMaintenanceDate, deleteMaintenanceDate, getMaintenanceDates } from '../../../controllers/MaintenanceDateController';

export default function Maintenance() {
    const { getAccessTokenSilently } = useAuth0();
    
    const [maintenanceDates, setMaintenanceDates] = useState([]);

    const currDate = new Date();
    const localDate = new Date(currDate.getTime() - currDate.getTimezoneOffset() * 60000);
    const [maintenanceDate, setMaintenanceDate] = useState(localDate.toISOString().slice(0,16));
    const [hoursBeforeDateToNotify, setHoursBeforeDateToNotify] = useState('1');
    const [isLocking, setIsLocking] = useState(true); 

    const fetchData = async () => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            const newMaintenanceDates = await getMaintenanceDates(accessToken);
            newMaintenanceDates.sort((a,b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA.getTime() - dateB.getTime();
            });
            setMaintenanceDates(newMaintenanceDates);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='maintenance'>
            <h1>Maintenance</h1>
            <p>Set a date when maintenance should occur, and an amount of time before this date to show a maintenance warning message. After this date, the site will be locked down to non-admins until the warning message is cleared (if locking is enforced).</p>
            <h2>Add New Maintenance Date</h2>
            <div className='newMaintenanceDateDiv'>
                <div className='newMaintenanceDateDivField'>
                    <span>Date and Time</span>
                    <input 
                        type="datetime-local" 
                        value={maintenanceDate}
                        onChange={(e) => {
                            setMaintenanceDate(e.target.value);
                        }}/>
                </div>
                <div className='newMaintenanceDateDivField'>
                    <span>Hours Before Date To Notify</span>
                    <input 
                        type="number" 
                        value={hoursBeforeDateToNotify} 
                        onChange={(e) => {
                            setHoursBeforeDateToNotify(e.target.value);
                        }}/>
                </div>
                <div className='newMaintenanceDateDivField'>
                    <CheckBox 
                        checked={isLocking}
                        setChecked={async (newVal) => {
                            setIsLocking(newVal);
                        }}
                        text={'Is Locking'}/>
                </div>
                <Button
                    text={'Add New'}
                    borderColor={'transparent'}
                    color={'green'}
                    onClick={async () => {
                        if (!maintenanceDate) {
                            alert('Please enter a maintenance date');
                            return;
                        }
                        const date = new Date(maintenanceDate);
                        const dateString = date.toISOString();

                        const accessToken = await getAccessTokenSilently();
                        await addMaintenanceDate(accessToken, dateString, hoursBeforeDateToNotify, isLocking);
                        fetchData();
                    }}/>
            </div>
            <h2>Maintenance Dates</h2>
            {maintenanceDates.length === 0 && <span>No maintenance dates set.</span>}
            <div className='maintenanceDates'>
                {maintenanceDates.map((maintenanceDate, index) => { 
                    const dateObj = new Date(maintenanceDate.date + 'Z');
                    return (
                        <div className='maintenanceDateDiv' key={index}>
                            <span>{dateObj.toLocaleString()}</span>
                            <span>Notify {maintenanceDate.hoursBeforeDateToNotify} hours beforehand</span>
                            <span>{maintenanceDate.isLocking ? 'Locking' : 'Not Locking'}</span>
                            <Button
                                text={'Delete'}
                                borderColor={'transparent'}
                                color={'red'}
                                onClick={async () => {
                                    const accessToken = await getAccessTokenSilently();
                                    await deleteMaintenanceDate(accessToken, maintenanceDate.id);
                                    fetchData();
                                }}/>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}