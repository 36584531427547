import React from 'react';
import TopBar from '../TopBar/TopBar';
import About from './About';

const AboutWithTopBar = (props) => {
    return (
        <div>
            <TopBar user={props.user}/>
            <About/>
        </div>
    );
};

export default AboutWithTopBar;
