import { callApi } from "./Utils";

// Main filter setting
export const getSelectedFilterType = async (accessToken) => {
    const selectedGrammarId = await callApi(`Filter/GetSelectedFilterType`, 'GET', accessToken);
    return selectedGrammarId;
}

export const putSelectedFilterType = async (accessToken, filterType) => {
    const success = await callApi(`Filter/SetSelectedFilterType?filterType=${filterType}`, 'PUT', accessToken);
    return success;
}

// Chapter filter Type
export const getDbSelectedBookId = async (accessToken) => {
    return await callApi(`Filter/GetSelectedBookId`, 'GET', accessToken);
}

export const setDbSelectedBookId = async (accessToken, bookId) => {
    return await callApi(`Filter/SetSelectedBookId?bookId=${bookId}`, 'PUT', accessToken);
}

export const getDbSelectedBookChapters = async (accessToken) => {
    return await callApi(`BookChapter/GetFromSelectedBook`, 'GET', accessToken);
}

export const selectBookChapter = async (accessToken, bookId, chapterId, selected) => {
    await callApi(`Filter/SelectBookChapter?bookId=${bookId}&chapterId=${chapterId}&selected=${selected}`, 'POST', accessToken);
}

// Grammar filter type
export const getSelectedGrammarId = async (accessToken) => {
    const selectedGrammarId = await callApi(`Filter/GetSelectedGrammarId`, 'GET', accessToken);
    return selectedGrammarId;
}

export const putSelectedGrammarId = async (accessToken, grammarId) => {
    const success = await callApi(`Filter/SetSelectedGrammarId?grammarId=${grammarId}`, 'PUT', accessToken);
    return success;
}

export const selectGrammarChapter = async (accessToken, grammarId, chapterId, selected) => {
    await callApi(`Filter/SelectGrammarChapter?grammarId=${grammarId}&chapterId=${chapterId}&selected=${selected}`, 'POST', accessToken);
}