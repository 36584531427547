import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import './Contact.css';

import React from 'react';
import { sendMessage } from '../../controllers/UserController';
import { useAuth0 } from '@auth0/auth0-react';

export default function Contact() {
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();

    const [subject, setSubject] = React.useState('');
    const [body, setBody] = React.useState('');

    const [sendActivated, setSendActivated] = React.useState(false);

    const sendMessageToDatabase = async () => {
        const accessToken = await getAccessTokenSilently();
        const isSuccess = await sendMessage(accessToken, {
            subject: subject,
            body: body
        });
        return isSuccess;
    }

    if (sendActivated) {
        return (
            <div className='contactDiv'>
                <div className='contactForm'>
                    <h1>Message Received</h1>
                    <p>
                        Thank you for your feedback! Your message has been saved successfully. We will review it shortly. If you asked a question or otherwise requested a response, we may reach out to you at the email address associated with your account.
                    </p>
                    <div className='messageReceivedButtons'>
                        <Button
                            text={'Back to Previous Page'}
                            color={'green'}
                            onClick={() => {
                                navigate(-1);
                            }}/>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='contactDiv'>
            <div className='contactForm'>
                <h1>Contact Us</h1>
                <p>
                    We welcome any and all feedback about your experience with this app! Please let us know if you notice any errors or have any suggestions for improvement. If you ask a question or otherwise request a response, we may reach out to you at the email address associated with your account.
                </p>
                <input 
                    type="text" 
                    value={subject} 
                    onChange={(e) => {
                        const newVal = e.target.value;
                        setSubject(newVal);
                    }}
                    placeholder="Subject line..."/>
                <textarea
                    value={body}
                    onChange={(e) => {
                        const newVal = e.target.value;
                        setBody(newVal);
                    }}
                    placeholder="Message content..."/>
                <div className='contactButtons'>
                    <Button
                        text={'Cancel'}
                        color={'red'}
                        borderColor={'transparent'}
                        onClick={() => {
                            navigate(-1);
                        }}/>
                    <Button
                        text={'Send Message'}
                        color={'green'}
                        borderColor={'transparent'}
                        onClick={() => {
                            sendMessageToDatabase().then(isSuccess => {
                                if (isSuccess) {
                                    setSendActivated(true);
                                }
                            });
                        }}/>
                </div>
            </div>
        </div>
    );
}