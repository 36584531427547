import React from 'react';
import './InfoButton.css';
import { useNavigate } from 'react-router-dom';

export default function InfoButton(props) {
    const navigate = useNavigate();
    const enabled = props.enabled ?? true;
    const style = props.style;
    const infoPath = props.infoPath ?? '/';

    const buttonStyle = {
        ...style
    };

    // Show a disabled button here if needed
    if (!enabled) {
        return (
            <div 
                className={'button infoButton disabledButton'}
                style={buttonStyle}>
                <span>?</span>
            </div>
        );
    }

    return (
        <div 
            className={'button infoButton enabledButton'}
            onClick={() => {
                navigate(infoPath);
            }}
            style={buttonStyle}>
            <span>?</span>
        </div>
    );
}