import './DesktopVerseStatus.css';

import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { getChapterLearnedStatus, getLemmaLearnedStatus, getVerseLearnedStatus } from '../../controllers/ReviewController';
import { getProgressColor } from '../BookSelector';
import { useParams } from 'react-router-dom';

const DesktopVerseStatus = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { level } = useParams();

    const [statusList, setStatusList] = useState(undefined);
    const [pixelClassName, setPixelClassName] = useState('');
    
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
                switch(level) {
                    case 'chapter':
                        setPixelClassName('chapterPixel');
                        getChapterLearnedStatus(accessToken).then(response => {
                            setStatusList(response);
                        });
                        break;
                    case 'verse':
                        setPixelClassName('versePixel');
                        getVerseLearnedStatus(accessToken).then(response => {
                            setStatusList(response);
                        });
                        break;
                    case 'lemma':
                        setPixelClassName('lemmaPixel');
                        getLemmaLearnedStatus(accessToken).then(response => {
                            setStatusList(response);
                        });
                        break;
                }
            }
        }
        fetchData();
    }, [level]);

    return (
        <div className='desktopVerseStatus'>
            {statusList?.map((status, index) => (
                <div 
                    key={index}
                    className={pixelClassName}
                    style={{
                        backgroundColor: getProgressColor(status/100)
                    }}/>
            ))}
        </div>
    );
};

export default DesktopVerseStatus;
