import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import CheckBox from './CheckBox';
import { getProgressColor } from './BookSelector';
import { selectBookChapter } from '../controllers/FilterController';

export default function BookChapterSelector(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [titleColor, setTitleColor] = useState('white');

    const selected = props.chapter.isSelected;
    const setSelected = props.setSelected;

    useEffect(() => {
        const learnedWordRatio = props.chapter.learnedLemmas/props.chapter.totalLemmas;
        const titleColor = getProgressColor(learnedWordRatio);
        setTitleColor(titleColor);
    }, [props.chapter.learnedLemmas, props.chapter.totalLemmas]);

    return (
        <div className='book'>
            <CheckBox 
                checked={selected}
                setChecked={async (newVal) => {
                    setSelected(newVal);
                    const accessToken = await getAccessTokenSilently();
                    await selectBookChapter(accessToken, props.bookId, props.chapter.id, newVal);
                }}
                text={`Chapter ${props.chapter.id} (${props.chapter.learnedLemmas}/${props.chapter.totalLemmas} words)`}
                textColor={titleColor}/>
        </div>
    );
}