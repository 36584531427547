import './EditLemmas.css';

import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useMemo, useState } from 'react';
import { editLemma, getLemmas } from '../../../controllers/LemmaController';
import Button from '../../Button/Button';
import CheckBox from '../../CheckBox';
import toast, { Toaster } from 'react-hot-toast';

function EditLemmas() {
    const { getAccessTokenSilently } = useAuth0();

    const [lemmas, setLemmas] = useState([]);
    const [selectedLemma, setSelectedLemma] = useState(null);
    const [searchText, setSearchText] = useState('');

    const [greekWord, setGreekWord] = useState('');
    const [definition, setDefinition] = useState('');
    const [freq, setFreq] = useState(0);
    const [isProper, setIsProper] = useState(false);

    const searchLemmas = useMemo(() => {
        let newSearchLemmas = lemmas.filter((lemma) => {
            const wordPasses = lemma.word.toLowerCase().includes(searchText.toLowerCase());
            const definitionPasses = lemma.definition.toLowerCase().includes(searchText.toLowerCase());
            return wordPasses || definitionPasses;
        });
        const maxLemmasToShow = 100;
        if (newSearchLemmas.length > maxLemmasToShow) {
            newSearchLemmas = newSearchLemmas.slice(0, maxLemmasToShow);
        }
        return newSearchLemmas;
    }, [lemmas, searchText]);

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
                const newLemmas = await getLemmas(accessToken);
                newLemmas.sort((a,b) => {
                    return a.word.localeCompare(b.word);
                });
                setLemmas(newLemmas);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        setGreekWord(selectedLemma?.word ?? '');
        setDefinition(selectedLemma?.definition ?? '');
        setFreq(selectedLemma?.frequency ?? '');
        setIsProper(selectedLemma?.isProperName ?? false);
    }, [selectedLemma]);

    const saveLemmaEdits = () => {
        getAccessTokenSilently().then(accessToken => {
            if (accessToken) {
                const newLemma = {
                    id: selectedLemma.id,
                    word: greekWord,
                    definition: definition,
                    frequency: freq,
                    isProperName: isProper
                };
                editLemma(accessToken, newLemma).then((success) => {
                    if (success) {
                        toast.success('Lemma saved successfully.');

                        // Make the change in the UI
                        const newLemmas = lemmas.map(i => {
                            if (i.id === selectedLemma.id) {
                                return newLemma;
                            }
                            return i;
                        });
                        setLemmas(newLemmas);
                        setSelectedLemma(undefined);
                    } else {
                        toast.error('Error saving lemma.');
                    }
                });
            }
        });
    }

    const lemmaCount = lemmas.length;
    const searchLemmaCount = searchLemmas.length;

    return (
        <div className='editLemmas'>
            <div className='lemmaList'>
                <div className='header'>
                    <h1>Lemmas</h1>
                    <Button
                        text='Add New'
                        color='green'
                        onClick={() => {
                            setSelectedLemma({
                                id: 0
                            });
                        }}/>
                </div>
                <span className='showingNumbLemmas'>
                    Showing {searchLemmaCount.toLocaleString()} of {lemmaCount.toLocaleString()}
                </span>
                <input 
                    type="text" 
                    value={searchText} 
                    onChange={(e) => {
                        const newVal = e.target.value;
                        setSearchText(newVal);
                    }}
                    placeholder="Search for a lemma..."/>
                {searchLemmaCount === 0 &&
                <p>No lemmas to show.</p>}
                <div className='lemmaListItems'>
                    {searchLemmas.map((lemma, index) => {
                        return (
                            <div 
                                className={'lemmaPreview'}
                                key={index}
                                onClick={() => {
                                    setSelectedLemma(lemma);
                                }}>
                                <span className='lemmaPreviewWord'>{lemma.word}</span>
                                <span className='lemmaPreviewDefinition'>{lemma.definition}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className='lemmaViewer'>
                {selectedLemma != null &&
                <div className='lemma'>
                    <div className='lemmaActions'>
                        Actions: 
                        {selectedLemma.id !== 0 &&
                        <Button
                            text='Save Edits'
                            color='green'
                            onClick={() => {
                                saveLemmaEdits();
                            }}/>}
                        {selectedLemma.id !== 0 &&
                        <Button
                            text='Delete Lemma'
                            color='red'
                            enabled={false}/>}
                        {selectedLemma.id === 0 &&
                        <Button
                            text='Create New Lemma'
                            color='green'
                            enabled={false}/>}
                    </div>
                    <div className='lemmaField'>
                        <span>Greek Word:</span>
                        <TextInput
                            value={greekWord}
                            setValue={setGreekWord}
                            width='30rem'/>
                    </div>
                    <div className='lemmaField'>
                        <span>English Definition:</span>
                        <TextInput
                            value={definition}
                            setValue={setDefinition}
                            width='30rem'/>
                    </div>
                    <div className='lemmaField'>
                        <span>Frequency:</span>
                        <NumberInput
                            value={freq}
                            setValue={setFreq}
                            width='5rem'/>
                    </div>
                    <div className='lemmaField'>
                        <span>Is Proper Noun:</span>
                        <CheckBox
                            checked={isProper}
                            setChecked={setIsProper}/>
                    </div>
                </div>}
            </div>
            <Toaster position='bottom-right'/>
        </div>
    );
};

export default EditLemmas;

function TextInput(props) {
    const { value, setValue, placeholder, width } = props;
    return (
        <input 
            className='textInput'
            style={{width: width}}
            type="text" 
            value={value} 
            onChange={(e) => {
                const newVal = e.target.value;
                setValue(newVal);
            }}
            placeholder={placeholder}/>
    );
}

function NumberInput(props) {
    const { value, setValue, placeholder, width } = props;
    return (
        <input 
            className='textInput'
            style={{width: width}}
            type="number" 
            value={value} 
            onChange={(e) => {
                const newVal = e.target.value;
                setValue(newVal);
            }}
            placeholder={placeholder}/>
    );
}