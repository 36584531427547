import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import CheckBox from './CheckBox';
import { getProgressColor } from './BookSelector';
import { selectGrammarChapter } from '../controllers/FilterController';

export default function GrammarChapterSelector(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [chapterTitleColor, setChapterTitleColor] = useState('white');

    const selected = props.chapter.isSelected;
    const setSelected = props.setSelected;

    useEffect(() => {
        const learnedWordRatio = props.chapter.learnedLemmaCount/props.chapter.lemmaCount;
        const chapterTitleColor = getProgressColor(learnedWordRatio);
        setChapterTitleColor(chapterTitleColor);
    }, [props.chapter.learnedLemmaCount, props.chapter.lemmaCount]);

    const chapterDisplayName = truncateString(props.chapter.name, 30);

    return (
        <div className='book'>
            <CheckBox 
                checked={selected}
                setChecked={async (newVal) => {
                    setSelected(newVal);
                    const accessToken = await getAccessTokenSilently();
                    await selectGrammarChapter(accessToken, props.grammarId, props.chapter.id, newVal);
                }}
                text={`${chapterDisplayName} (${props.chapter.learnedLemmaCount}/${props.chapter.lemmaCount} words)`}
                textColor={chapterTitleColor}/>
        </div>
    );
}

function truncateString(str, num) {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
}