import './DesktopDashboard.css';

import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { getActiveUsersByHour, getDailySignUpsGraphData, getDailyUserGraphData, getOnlineUsers, getStorageUsage, getTotalGuesses, getTotalUserCount, getUnreadMessageCount, getUnrepliedMessageCount } from '../../../controllers/AdminStatsController';
import prettyBytes from 'pretty-bytes';

import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import Spinner from '../../Spinner';

function Dashboard() {
    const { getAccessTokenSilently } = useAuth0();
    
    const [unreadMessageCount, setUnreadMessageCount] = useState(undefined);
    const [unrepliedMessageCount, setUnrepliedMessageCount] = useState(undefined);
    const [onlineNow, setOnlineNow] = useState(undefined);
    const [totalUserCount, setTotalUserCount] = useState(undefined);
    const [totalGuesses, setTotalGuesses] = useState(undefined);
    const [usage, setUsage] = useState(undefined);
    const [activeUsersByHour, setActiveUsersByHour] = useState(undefined);

    // Grab a new lesson when the component first loads
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
                setUnreadMessageCount(await getUnreadMessageCount(accessToken));
                setUnrepliedMessageCount(await getUnrepliedMessageCount(accessToken));
                setOnlineNow(await getOnlineUsers(accessToken));
                setTotalUserCount(await getTotalUserCount(accessToken));
                setTotalGuesses(await getTotalGuesses(accessToken));
                setUsage(await getStorageUsage(accessToken));
                setActiveUsersByHour(await getActiveUsersByHour(accessToken));
            }
        }
        fetchData();
    }, []);

    return (
        <div>
            <span className='desktopAdminWelcome'>Welcome, powerful one.</span>
            <div className='desktopAdminDashboard'>
                <DashboardStat
                    name='Unread Messages'
                    content={unreadMessageCount != null ? (
                        <span className='adminDashVal'>{unreadMessageCount.toLocaleString()}</span>
                    ) : null}/>
                <DashboardStat
                    name='Unreplied-to Messages'
                    content={unrepliedMessageCount != null ? (
                        <span className='adminDashVal'>{unrepliedMessageCount.toLocaleString()}</span>
                    ) : null}/>
                <DashboardStat
                    name='Online Now'
                    content={onlineNow != null ? (
                        <span className='adminDashVal'>{onlineNow.toLocaleString()}</span>
                    ) : null}/>
                <DashboardStat
                    name='Total Users'
                    content={totalUserCount != null ? (
                        <span className='adminDashVal'>{totalUserCount.toLocaleString()}</span>
                    ) : null}/>
                <DashboardStat
                    name='Total Guesses'
                    content={totalGuesses != null ? (
                        <span className='adminDashVal'>{totalGuesses.toLocaleString()}</span>
                    ) : null}/>
                <DashboardStat
                    name='Database'
                    content={usage != null ? (
                        <span>
                            <span className='adminDashVal'>{prettyBytes(usage.used)}</span>
                        </span>
                    ) : null}/>
                <DashboardStat
                    name='API Disk Left'
                    content={usage != null ? (
                        <span>
                            <span className='adminDashVal'>{prettyBytes(usage.availible)}</span>
                        </span>
                    ) : null}/>
                <span>Active Users By Hour</span>
                <Spinner show={activeUsersByHour == null}/>
                <div style={{
                    width: '60rem'
                }}>
                    <Line 
                        data={
                            {
                                labels: activeUsersByHour?.map(item => {
                                    return formatDateString(item.date)
                                }),
                                datasets: [
                                {
                                    label: 'Users Online',
                                    data: activeUsersByHour?.map(item => item.count)
                                },
                                ],
                            } 
                        }/>
                </div>
            </div>
        </div>
    );
}
export default Dashboard;

function DashboardStat(props) {
    return (
        <span>{props.name}: {props.content == null ? <span className='loadingText'>loading...</span> : props.content}</span>
    );
}

function formatDateString(inputString) {
    const dateObj = convertStringToDate(inputString);

    const options = { 
        hour: 'numeric' 
    };
      
    const dateString = dateObj?.toLocaleString('en-US', options);

    return dateString;
}

function convertStringToDate(inputString) {
    // Parse the input string into separate components
    const [datePart, timePart] = inputString.split(' ');
  
    // Extract year, month, day, hour from the date part
    const [year, month, day] = datePart.split('-').map(Number);
  
    // Extract hour from the time part
    const hour = parseInt(timePart);
  
    // Create a new Date object with the components (in UTC)
    const resultDate = new Date(Date.UTC(year, month - 1, day, hour));
  
    // Check if the Date object is valid
    if (isNaN(resultDate.getTime())) {
      console.error('Invalid date string');
      return null;
    }
  
    return resultDate;
}