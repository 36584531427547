import './DesktopAdminPage.css';
import React from 'react';
import Button from '../Button/Button';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function DesktopAdminPage() {
    const navigate = useNavigate();
    const location = useLocation();

    const sidebarButton = (text, path) => {
        const isSelected = location.pathname === path;
        const textColor = isSelected ? 'goldenrod' : 'lightgray';
        return (
            <Button 
                text={text} 
                textColor={textColor}
                onClick={() => navigate(path)}/>
        );
    }

    return (
        <div className='adminPage'>
            <div className='adminPageSidebar'>
                {sidebarButton('Dashboard', '/admin/dashboard')}
                {sidebarButton('User Messages', '/admin/user-messages')}
                {sidebarButton('Edit Grammars', '/admin/edit-grammars')}
                {sidebarButton('Edit Lemmas', '/admin/edit-lemmas')}
                {sidebarButton('Extra Charts', '/admin/charts')}
                {sidebarButton('Maintenance', '/admin/maintenance')}
            </div>
            <div className='adminPageContent'>
                <Outlet/>
            </div>
        </div>
    );
}
export default DesktopAdminPage;