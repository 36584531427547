import './Spinner.css';
import React from 'react';

export default function Spinner(props) {
    const show = props.show;

    if (show) {
        return (
            <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }
    else {
        return <div/>
    }
}