import React, { useState } from 'react';
import './ImportForm.css';
import { useDropzone } from 'react-dropzone';
import { read, utils } from 'xlsx';
import { useAuth0 } from '@auth0/auth0-react';
import { importGrammar } from '../../../../controllers/GrammarController';
import Button from '../../../Button/Button';

function ImportForm({importConfirmed}) {
    const { getAccessTokenSilently } = useAuth0();
    const [excelData, setExcelData] = useState(null);

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
    
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = read(data, { type: 'array' });
        
            const grammars = utils.sheet_to_json(workbook.Sheets['Grammar']);

            if (grammars.length === 1) {
                const grammar = grammars[0];
                const chapters = utils.sheet_to_json(workbook.Sheets['Chapters']);
                const lemmas = utils.sheet_to_json(workbook.Sheets['Lemmas']);

                setExcelData({
                    fileName: file.name,
                    grammar: grammar,
                    chapters: chapters,
                    lemmas: lemmas
                });
            }
        };
    
        reader.readAsArrayBuffer(file);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const importNewGrammar = async (newGrammar) => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            const changesWereMade = await importGrammar(accessToken, newGrammar);
            importConfirmed(changesWereMade);
        }
    }

    return (
        <div className='importForm'>
            <p>Upload an excel file to overwrite the content of this grammar.</p>
            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <p>Drag and drop an Excel file here, or <span>click</span> to select one.</p>
            </div>
            {excelData != null && (
                <div className='importFileStats'>
                    <span><b>Selected File:</b> {excelData.fileName}</span>
                    <span><b>Grammar Name:</b> {excelData.grammar.name}</span>
                    <span><b>Chapter Count:</b> {excelData.chapters.length}</span>
                    <span><b>Lemma Count:</b> {excelData.lemmas.length}</span>
                </div>
            )}
            <Button
                text={'Import'}
                textColor='goldenrod'
                onClick={() => {
                    const newGrammar = {
                        name: excelData.grammar.name,
                        chapters: excelData.chapters,
                        lemmas: excelData.lemmas
                    };
                    importNewGrammar(newGrammar);
                }}/>
        </div>
    );
}

export default ImportForm;
