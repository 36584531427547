import { callApi } from "./Utils";

export const getWordsGuessedByDate = async (accessToken) => {
    const usage = await callApi('AdminStats/GetWordsGuessedByDate', 'GET', accessToken);
    return usage;
}

export const getWordsGuessedByHour = async (accessToken) => {
    const usage = await callApi('AdminStats/GetWordsGuessedByHour', 'GET', accessToken);
    return usage;
}

export const getDailyUserGraphData = async (accessToken) => {
    const result = await callApi('AdminStats/GetDailyUserGraphData', 'GET', accessToken);
    return result;
}

export const getDailySignUpsGraphData = async (accessToken) => {
    const result = await callApi('AdminStats/GetDailySignUpsGraphData', 'GET', accessToken);
    return result;
}

export const getActiveUsersByDate = async (accessToken) => {
    const usage = await callApi('AdminStats/GetActiveUsersByDate', 'GET', accessToken);
    return usage;
}

export const getActiveUsersByHour = async (accessToken) => {
    const usage = await callApi('AdminStats/getActiveUsersByHour', 'GET', accessToken);
    return usage;
}

export const getStorageUsage = async (accessToken) => {
    const usage = await callApi('AdminStats/GetStorageUsage', 'GET', accessToken);
    return usage;
}

export const getUnreadMessageCount = async (accessToken) => {
    const result = await callApi('AdminStats/GetUnreadMessageCount', 'GET', accessToken);
    return result;
}

export const getUnrepliedMessageCount = async (accessToken) => {
    const result = await callApi('AdminStats/GetUnrepliedMessageCount', 'GET', accessToken);
    return result;
}

export const getTotalUserCount = async (accessToken) => {
    const result = await callApi('AdminStats/GetTotalUserCount', 'GET', accessToken);
    return result;
}

export const getTotalGuesses = async (accessToken) => {
    const result = await callApi('AdminStats/GetTotalGuesses', 'GET', accessToken);
    return result;
}

export const getOnlineUsers = async (accessToken) => {
    const result = await callApi('AdminStats/GetOnlineUsers', 'GET', accessToken);
    return result;
}