import './DesktopTopBar.css';
import React from 'react';

function DesktopTopBarButton(props) {
    return (
        <button 
            className={props.toggled ? 'topBarButton topBarButtonToggled' : 'topBarButton topBarButtonOff'}
            onClick={() => props.onClick()}>
            <div 
                className='topBarButtonText'>
                {props.name}
            </div>
        </button>
    );
}
export default DesktopTopBarButton;