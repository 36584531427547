import { callApi } from "./Utils";

export const getGrammars = async (accessToken) => {
    const grammars = await callApi('Grammar/GetGrammars', 'GET', accessToken);
    return grammars;
}

export const deleteGrammar = async (accessToken, id) => {
    const success = await callApi(`Grammar/DeleteGrammar?id=${id}`, 'DELETE', accessToken);
    return success;
}

export const importGrammar = async (accessToken, grammarImport) => {
    const success = await callApi(`Grammar/ImportGrammar`, 'POST', accessToken, grammarImport);
    return success;
}

export const getGrammarChapters = async (accessToken, grammarId) => {
    const grammarChapters = await callApi(`Grammar/GetGrammarChapters?grammarId=${grammarId}`, 'GET', accessToken);
    return grammarChapters;
}

export const getGrammarChapterLemmas = async (accessToken, grammarId, chapterId) => {
    const grammarChapterLemmas = await callApi(`Grammar/GetGrammarChapterLemmas?grammarId=${grammarId}&chapterId=${chapterId}`, 'GET', accessToken);
    return grammarChapterLemmas;
}

export const deleteGrammarChapter = async (accessToken, grammarId, chapterId) => {
    const success = await callApi(`Grammar/DeleteGrammarChapter?grammarId=${grammarId}&chapterId=${chapterId}`, 'DELETE', accessToken);
    return success;
}

export const deleteGrammarChapterLemma = async (accessToken, grammarId, chapterId, lemmaId) => {
    const success = await callApi(`Grammar/DeleteGrammarChapterLemma?grammarId=${grammarId}&chapterId=${chapterId}&lemmaId=${lemmaId}`, 'DELETE', accessToken);
    return success;
}