import React, { useEffect, useState } from 'react';

import './LearningSettings.css';
import Button from '../../Button/Button';
import { useNavigate } from 'react-router-dom';
import Switch from '../../Switch/Switch';
import { useAuth0 } from '@auth0/auth0-react';
import { getUser, setIncludeProperNames as putIncludeProperNames, putPlaySounds, setDbLanguage } from '../../../controllers/UserController';
import Spinner from '../../Spinner';
import SelectBox from '../../SelectBox/SelectBox';

const LearningSettings = () => {
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    
    const [includeProperNames, setIncludeProperNames] = useState(null);
    const [playSounds, setPlaySounds] = useState(null);
    const [language, setLanguage] = useState(0);
    
    useEffect(() => {
        if (isAuthenticated) {
            fetchDataOnAuthentication();
        }
    }, [isAuthenticated]);

    const fetchDataOnAuthentication = async () => {
        const accessToken = await getAccessTokenSilently();
        // This will create a new db object for the user if one does not already exist.
        const newUser = await getUser(accessToken);
        setIncludeProperNames(newUser.includeProperNames);
        setPlaySounds(newUser.playSounds);
        setLanguage(newUser.language);
    }

    const includeProperNamesValueChanged = async (val) => {
        // Change UI
        setIncludeProperNames(val);
        
        // Change the value in the database
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            await putIncludeProperNames(accessToken, val);
        }
    };

    const playSoundsValueChanged = async (val) => {
        // Change UI
        setPlaySounds(val);
        
        // Change the value in the database
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            await putPlaySounds(accessToken, val);
        }
    };

    const languageValueChanged = async (val) => {
        // Change UI
        setLanguage(val);
        
        // Change the value in the database
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            await setDbLanguage(accessToken, val);
        }
    };

    const loading = playSounds == null || includeProperNames == null;

    return (
        <div className='learningSettings'>
            <h1>Settings</h1>
            {loading && <Spinner show={true}/>}
            {!loading &&
            <div className='learningSettingsContent'>
                <Switch 
                    enabled={includeProperNames} 
                    setEnabled={newVal => includeProperNamesValueChanged(newVal)}
                    text={'Include Proper Names'}
                    textColor={'rgb(179, 179, 179)'}/>
                <Switch 
                    enabled={playSounds} 
                    setEnabled={newVal => playSoundsValueChanged(newVal)}
                    text={'Play Sound Effects'}
                    textColor={'rgb(179, 179, 179)'}/>
                <div style={{height: '0.25rem'}}/>
                <label>Gloss Language</label>
                <SelectBox
                    options={[
                        {value: 0, label: 'English'},
                        {value: 1, label: 'Chinese Traditional'},
                        {value: 2, label: 'Chinese Simplified'}
                    ]}
                    value={language}
                    onChange={languageValueChanged}/>
                <p className='experimentalFeatureNote'>
                    Note: This language feature is experimental. Please let us know if you encounter any issues!
                </p>
                <div style={{height: '0.25rem'}}/>
                <div className='learningSettingsButtonHolder'>
                    <Button 
                        text={'Reset Learning'}
                        onClick={() => navigate("/reset-learning")}
                        color={'#333030'}
                        borderColor={'#333030'}
                        textColor={'#ff7300'}/>
                </div>
                <div className='learningSettingsButtonHolder'>
                    <Button 
                        text={'Log Out'}
                        onClick={() => navigate('/logout')}
                        color={'#333030'}
                        borderColor={'#333030'}
                        textColor={'#ff7300'}/>
                </div>
            </div>}
            <div className='learningSettingsBackButton'>
                <Button
                    text={'Back to Learning'}
                    onClick={() => navigate("/learning")}
                    color={'#333030'}
                    borderColor={'#333030'}
                    textColor={'#b8b8b8'}
                    icon='back'/>
            </div>
        </div>
    );
};

export default LearningSettings;