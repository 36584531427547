import { callApi } from "./Utils";

export const getMessages = async (accessToken) => {
    const response = await callApi('UserMessages/GetMessages', 'GET', accessToken);
    return response;
}

export const readMessage = async (accessToken, messageId) => {
    const response = await callApi(`UserMessages/ReadMessage?messageId=${messageId}`, 'PUT', accessToken);
    return response;
}

export const markReply = async (accessToken, messageId, value) => {
    const response = await callApi(`UserMessages/MarkResponded?messageId=${messageId}&value=${value.toString()}`, 'PUT', accessToken);
    return response;
}