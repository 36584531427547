import './TopBar.css';
import React, { useEffect, useState } from 'react';
import TopBarButton from './TopBarButton';
import { useNavigate, useLocation } from "react-router-dom";
import chartIcon from '../../icons/chart-icon.png';

function TopBar(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const isAdmin = props.user?.isAdmin ?? false;

    return (
        <div className='topBar'>
            <TopBarButton 
                name='Learn'
                toggled={location.pathname === '/learning'}
                onClick={() => navigate('/learning')}/>
            <TopBarButton 
                name='Filters'
                toggled={location.pathname === '/filter-by-book'}
                onClick={() => navigate('/filter-by-book')}/>
            <TopBarButton 
                name='About'
                toggled={location.pathname === '/about'}
                onClick={() => navigate('/about')}/>
            {isAdmin &&
            <TopBarButton 
                name={
                    <img 
                        src={chartIcon} 
                        alt='' 
                        className='chartIcon'
                        width={'40em'}/>
                }
                isIcon={true}
                toggled={location.pathname === '/admin/dashboard'}
                onClick={() => navigate('/admin/dashboard')}/>}
        </div>
    );
}
export default TopBar;