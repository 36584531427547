import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getLesson } from '../controllers/LessonController';
import LessonView from './LessonView';
import Spinner from './Spinner';
import { useNavigate } from "react-router-dom";

export default function LessonPage(props) {
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [lesson, setLesson] = useState(undefined);

    // Grab a new lesson when the component first loads
    useEffect(() => {
        fetchLesson();
    }, []);

    const fetchLesson = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            const newLesson = await getLesson(accessToken);
            setLesson(newLesson);
        }
    }, [getAccessTokenSilently]);

    return (
        <div className='lessonPage'>
            {isAuthenticated && lesson != null &&
            <LessonView 
                lesson={lesson}
                endLesson={() => navigate('/')}
                newLesson={() => fetchLesson()}/>}
            {(!isAuthenticated || lesson == null) &&
            <div style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '5em',
                marginBottom: '5em'
            }}>
                <Spinner show={true}/>
            </div>}
        </div>
    );
}