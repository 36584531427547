import './Slider.css';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import InfoButton from '../InfoButton/InfoButton';

function Slider(props) {
    const {
        min,
        max,
        step,
        value,
        onChange,
        renderTooltip,
        label,
        style,
        renderValue,
        visible,
        infoButtonPath
    } = props;

    if (visible === false) {
        return <div/>;
    }

    return (
        <div className={'sliderDiv'} style={style}>
            <span className={'sliderLabel'}>{label}</span>
            {infoButtonPath &&
            <InfoButton infoPath={infoButtonPath}/>}
            <input 
                className={'slider'}
                type="range" 
                min={min}
                max={max}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                step={step}
                data-tooltip-id="sliderTooltip" 
                data-tooltip-content={renderTooltip ? renderTooltip(value) : ''}/>
            <Tooltip 
                id="sliderTooltip" 
                style={{color: 'goldenrod'}}/>
            {renderValue != null &&
            <span className={'sliderValueText'}>{renderValue(value)}</span>}
        </div>
    );
}
export default Slider;