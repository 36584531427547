import './UsageHeatMap.css';
import React, { useState, useEffect } from 'react';

import { getActiveUsersByHour, getDailySignUpsGraphData, getDailyUserGraphData, getWordsGuessedByHour } from '../../../controllers/AdminStatsController';
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from '../../Spinner';

import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';


function ExtraCharts() {
    const { getAccessTokenSilently } = useAuth0();

    const [dailyUsersGraphData, setDailyUsersGraphData] = useState(undefined);
    const [signUpGraphData, setSignUpGraphData] = useState(undefined);

    // Grab a new lesson when the component first loads
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
                setSignUpGraphData(await getDailySignUpsGraphData(accessToken));
                setDailyUsersGraphData(await getDailyUserGraphData(accessToken));
            }
        }
        fetchData();
    }, []);

    return (
        <div className='usageHeatMap'>
            <div className='usageHeatVerticalContent'> 
                <span className='header'>Daily Users</span>
                <Spinner show={dailyUsersGraphData == null}/>
                {dailyUsersGraphData != null &&
                <div style={{
                    width: '60rem'
                }}>
                    <Line 
                        data={
                            {
                                labels: dailyUsersGraphData.labels,
                                datasets: dailyUsersGraphData.datasets
                            } 
                        }/>
                </div>}
                <span className='header'>Daily Sign Ups</span>
                <Spinner show={signUpGraphData == null}/>
                {signUpGraphData != null &&
                <div style={{
                    width: '60rem'
                }}>
                    <Line 
                        data={
                            {
                                labels: signUpGraphData.labels,
                                datasets: signUpGraphData.datasets
                            } 
                        }/>
                </div>}
            </div>
        </div> 
    );
}
export default ExtraCharts;