import './DesktopReviewListPage.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getReviewList } from '../../controllers/ReviewController';
import Spinner from '../Spinner';
import { getProgressColor } from '../BookSelector.jsx';
import { Tooltip } from 'react-tooltip';

export default function DesktopReviewListPage(props) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [reviewList, setReviewList] = useState(undefined);

    // Grab the review list when the component first loads
    useEffect(() => {
        fetchReviewList();
    }, []);

    const fetchReviewList = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            const newReviewList = await getReviewList(accessToken);
            setReviewList(newReviewList);
        }
    }, [getAccessTokenSilently, setReviewList]);

    const listCardUI = (item, index) => {
        const learned = item.percentLearned === 100;
        const showLearnedStatus = item.attempts > 0 && item.percentLearned < 100;
        const pieDegrees = (item.percentLearned/100) * 360;
        const pieColor = getProgressColor(item.percentLearned/100);
        return (
            <div 
                className='desktopReviewListCard' 
                key={index}
                data-tooltip-id="desktopReviewListCardTooltip" 
                data-tooltip-content={customTooltip(item)}>
                <h2>{item.word}</h2>
                <div className='desktopReviewItemStatusSection'>
                    <div className='desktopReviewItemLearned'>{learned ? "learned" : ""}</div>
                    <div className='desktopReviewItemNew'>{item.attempts === 0 ? "new" : ""}</div>
                    {showLearnedStatus &&
                    <div className='desktopReviewItemStatus'>
                        learned status
                        <div 
                            className='desktopReviewItemStatusPie'
                            style={{
                                background: `conic-gradient(${pieColor} ${pieDegrees}deg,gray ${pieDegrees}deg)`
                            }}>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }

    return (
        <div className='desktopReviewListWrapper'>
            {isAuthenticated && reviewList != null &&
            <div className='desktopReviewList'>
                <h1>Review List ({reviewList.length.toLocaleString()} words)</h1>
                <p>
                    This list only shows words in your review list that apply to your current filters.
                </p>
                {reviewList.sort((a, b) => a.percentLearned - b.percentLearned).map((item, index) => (
                    listCardUI(item, index)
                ))}
            </div>}
            {(!isAuthenticated || reviewList == null) &&
            <div style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '5em',
                marginBottom: '5em'
            }}>
                <Spinner show={true}/>
            </div>}
            <Tooltip 
                id="desktopReviewListCardTooltip"
                place='right'/>
        </div>
    );
}

const customTooltip = (reviewItem) => {
    if (reviewItem == null || reviewItem.definition == null)
        return 'No data';

    return `${reviewItem.definition}`;
};