import React from 'react';
import './Popup.css';
import Button from '../Button/Button';

const Popup = ({ isOpen, onClose, content, title, customPopupClass }) => {
    if (!isOpen) return <div/>;
    return (
        <div className="popupWrapper">
            <div className={"popup " + customPopupClass}>
                <div className="popupHeader">
                    <h1>{title}</h1>
                    <Button 
                        text={'X'} 
                        textColor={'slategray'}
                        color={'transparent'}
                        borderColor={'transparent'}
                        onClick={onClose}/>
                </div>
                <div className="popupContent">
                    {content}
                </div>
            </div>
        </div>
    );
};

export default Popup;
