import './ReviewListPage.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getReviewList } from '../../controllers/ReviewController';
import Spinner from '../Spinner';
import { getProgressColor } from '../BookSelector.jsx';

export default function ReviewListPage(props) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [reviewList, setReviewList] = useState(undefined);

    // Grab the review list when the component first loads
    useEffect(() => {
        fetchReviewList();
    }, []);

    const fetchReviewList = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            const newReviewList = await getReviewList(accessToken);
            setReviewList(newReviewList);
        }
    }, [getAccessTokenSilently, setReviewList]);

    const listCardUI = (item, index) => {
        const learned = item.percentLearned === 100;
        const showLearnedStatus = item.percentLearned > 0 && item.percentLearned < 100;
        const pieDegrees = (item.percentLearned/100) * 360;
        const pieColor = getProgressColor(item.percentLearned/100);
        return (
            <div className='reviewListCard' key={index}>
                <h2><span>{index + 1}. </span>{item.word}</h2>
                <div className='reviewItemStat'>Attempts: {item.attempts}</div>
                <div className='reviewItemStat'>Correct: {item.correct}</div>
                <div className='reviewItemStat'>Streak: {item.streak}</div>   
                <div className='reviewItemLearned'>{learned ? "learned" : ""}</div>
                <div className='reviewItemNew'>{item.attempts === 0 ? "new" : ""}</div>
                {showLearnedStatus &&
                <div className='reviewItemStatus'>
                    learned status
                    <div 
                        className='reviewItemStatusPie'
                        style={{
                            background: `conic-gradient(${pieColor} ${pieDegrees}deg,gray ${pieDegrees}deg)`
                        }}>
                    </div>
                </div>}
            </div>
        );
    }

    return (
        <div>
            {isAuthenticated && reviewList != null &&
            <div className='reviewList'>
                {reviewList.sort((a, b) => b.attempts - a.attempts).map((item, index) => (
                    listCardUI(item, index)
                ))}
            </div>}
            {(!isAuthenticated || reviewList == null) &&
            <div style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '5em',
                marginBottom: '5em'
            }}>
                <Spinner show={true}/>
            </div>}
        </div>
    );
}