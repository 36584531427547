import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

export default function LogoutPage(props) {
    const { isAuthenticated, logout } = useAuth0();

    useEffect(() => {
        logout();
    }, []);

    return (
        isAuthenticated ? <p>Logging out...</p> : <p>Logout successful!</p>
    );
}