import './UserMessages.css';

import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useMemo, useState } from 'react';
import { getMessages, markReply, readMessage } from '../../../controllers/UserMessagesController';
import CheckBox from '../../CheckBox';

function UserMessages() {
    const { getAccessTokenSilently } = useAuth0();

    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [hideReplied, setHideReplied] = useState(true);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
                const newMessages = await getMessages(accessToken);
                newMessages.sort((a,b) => {
                    const dateA = new Date(a.dateCreated);
                    const dateB = new Date(b.dateCreated);
                    return dateB.getTime() - dateA.getTime();
                });
                setMessages(newMessages);
            }
        }
        fetchData();
    }, []);

    const filteredMessages = useMemo(() => {
        return messages.filter((message) => {
            if (hideReplied && message.isRespondedTo) {
                return false;
            }
            if (searchText.length > 0) {
                const lowerSearch = searchText?.toLowerCase();
                if (message.subject?.toLowerCase().includes(lowerSearch) ||
                    message.body?.toLowerCase().includes(lowerSearch) ||
                    message.userEmail?.toLowerCase().includes(lowerSearch) ||
                    message.userId?.toString().includes(lowerSearch)) {
                    return true;
                }
                return false;
            }
            return true;
        });
    }, [messages, hideReplied, searchText]);

    const selectMessage = async (message) => {
        setSelectedMessage(message);

        // Mark as read in the UI
        const newMessages = messages.map((m) => {
            if (m.id === message.id) {
                m.isRead = true;
            }
            return m;
        });
        setMessages(newMessages);

        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            await readMessage(accessToken, message.id);
        }
    }

    return (
        <div className='userMessages'>
            <div className='messageList'>
                <div className='messageListHeader'>
                    <h1>User Messages</h1>
                    <CheckBox
                        text={'Hide Replied To'}
                        checked={hideReplied}
                        setChecked={async (newVal) => {
                            setHideReplied(newVal);
                        }}/>
                </div>
                <input 
                    type="text" 
                    value={searchText} 
                    onChange={(e) => {
                        const newVal = e.target.value;
                        setSearchText(newVal);
                    }}
                    placeholder={`Search ${filteredMessages.length.toLocaleString()} messages...`}/>
                {filteredMessages.length === 0 &&
                <p>No messages to show.</p>}
                <div className='scrollableMessageList'>
                    {filteredMessages.map((message, index) => {
                        const dateObj = new Date(message.dateCreated + 'Z');
                        const maxSubjectLength = 50;


                        let subject = 'No Subject';
                        if (message.subject) {
                            subject = message.subject;
                        }
                        const formattedSubject = subject.length > maxSubjectLength ? subject.substring(0, maxSubjectLength) + '...' : subject;

                        let cssClass = 'messagePreview';
                        if (message.isRead) {
                            cssClass += ' readMessage';
                        }

                        return (
                            <div 
                                className={cssClass}
                                key={index}
                                onClick={() => {
                                    selectMessage(message);
                                }}>
                                <div className='messagePreviewHeader'>
                                    <span className='messagePreviewSubject'>{formattedSubject}</span>
                                    {!message.isRead &&
                                    <span className='messagePreviewUnreadMarker'>unread</span>}
                                    {message.isRead && !message.isRespondedTo &&
                                    <span className='messagePreviewReplyMarker'>awaiting reply</span>}
                                </div>
                                <span className='messagePreviewInfo'>
                                    From: 
                                    {message.userEmail &&
                                    <span> {message.userEmail}</span>}
                                    {!message.userEmail &&
                                    <span> User ID {message.userId}</span>}
                                </span>
                                <span className='messagePreviewInfo'>{dateObj.toLocaleString()}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className='messageViewer'>
                {selectedMessage != null && 
                <UserMessage 
                    selectedMessage={selectedMessage}
                    messages={messages}
                    setMessages={setMessages}/>}
            </div>
        </div>
    );
};

export default UserMessages;

function UserMessage(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [respTo, setRespTo] = useState(props.selectedMessage.isRespondedTo);

    useEffect(() => {
        setRespTo(props.selectedMessage.isRespondedTo);
    }, [props.selectedMessage.isRespondedTo]);

    const markReplyTo = async (newVal) => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            const success = await markReply(accessToken, selectedMessage.id, newVal);
            if (success) {
                const newMessages = props.messages.map((m) => {
                    if (m.id === selectedMessage.id) {
                        m.isRespondedTo = newVal;
                    }
                    return m;
                });
                props.setMessages(newMessages);
            }
        }
    }

    const selectedMessage = props.selectedMessage;
    const date = (new Date(selectedMessage.dateCreated + 'Z')).toLocaleString();
    const fromContent = selectedMessage.userEmail ? (
        <a href={`mailto:${selectedMessage.userEmail}`}>{selectedMessage.userEmail}</a>
    ) : (
        <span>User ID {selectedMessage.userId}</span>
    );
    let subject = 'No Subject';
    if (selectedMessage.subject) {
        subject = selectedMessage.subject;
    }
    return (
        <div className='userMessage'>
            <div className='messageTopBar'>
                <h1>{subject}</h1>
                <span>From: {fromContent}</span>
                <span>Received: {date}</span>
            </div>
            <p>
                {selectedMessage.body.split('\n').map((line, index) => (
                    <span key={index}>
                        {line}
                        <br />
                    </span>
                ))}
            </p>
            <div className='repliedToMarker'>
                <CheckBox
                    checked={respTo}
                    setChecked={(newVal) => {
                        setRespTo(newVal);
                        markReplyTo(newVal);
                    }}
                    text='Mark as replied to'
                    textColor='goldenrod'/>
            </div>
        </div>
    );
}
