import { callApi } from "./Utils";

export const getReviewList = async (accessToken) => {
    const reviewList = await callApi('review/GetReviewList', 'GET', accessToken);
    return reviewList;
}

export const getVerseLearnedStatus = async (accessToken) => {
    const reviewList = await callApi('review/GetVerseLearnedStatus', 'GET', accessToken);
    return reviewList;
}

export const getChapterLearnedStatus = async (accessToken) => {
    const reviewList = await callApi('review/getChapterLearnedStatus', 'GET', accessToken);
    return reviewList;
}

export const getLemmaLearnedStatus = async (accessToken) => {
    const reviewList = await callApi('review/getLemmaLearnedStatus', 'GET', accessToken);
    return reviewList;
}