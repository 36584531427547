import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Button from './Button/Button';

export default function LoginPage(props) {

    const { loginWithRedirect } = useAuth0();

    return (
        <div id='mainPage'>
            <div id="signInDiv">
                <h1>Welcome to Greek New Testament Vocab!</h1>
                <p style={{
                    maxWidth: '40em',
                    textAlign: 'left'
                }}>
                    You must sign in to use this website. User accounts are secure and are only used for tracking your progress as you study. This website is ad-free and free to use. We hope it helps you learn Greek!
                </p>
                <Button 
                    text={'Sign in / Sign up'}
                    onClick={loginWithRedirect}
                    color={'#3D3E51'}
                    borderColor={'#4C5265'}
                    textColor={'goldenrod'}
                    style={{
                        width: '8em',
                        fontSize: '1.4em'
                    }}/>
            </div>
            <div className='copyrightDiv'>
                © Peter Glenn 2024
            </div>
        </div>
    );
}