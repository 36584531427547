import './TopBar.css';
import React from 'react';

function TopBarButton(props) {
    let className = props.toggled ? 'topBarButton topBarButtonToggled' : 'topBarButton topBarButtonOff';
    if (props.isIcon) {
        className += ' topBarIconButton';
    }
    return (
        <button 
            className={className}
            onClick={() => props.onClick()}>
            <div 
                className='topBarButtonText'>
                {props.name}
            </div>
        </button>
    );
}
export default TopBarButton;